import { Box, Flex, Heading, Radio, RadioGroup, Stack, VStack } from '@chakra-ui/react'
import { BaseButton } from 'src/components/button/BaseButton'
import { EVENT_SIZE, EVENT_TYPE } from 'src/shared/constants'

export const Filters = ({ eventType, eventSize, onEventTypeHandle, onEventSizeHandle, onResetFilters }) => {
  return (
    <Box w={{ base: '100%', lg: '250px' }} ml={10}>
      <Flex display={'flex'} backgroundColor={'#FFFFFF'} border={'1px'} borderRadius={'5px'} borderColor={'#DEDEDE'} mr={10}>
        <Stack
          direction={{ base: 'row', lg: 'column' }}
          paddingLeft={'16px'}
          paddingTop={'10px'}
          paddingBottom={'10px'}
          spacing={'20px'}
        >
          <VStack w={'full'}>
            <Heading fontSize={'14px'}>Event type</Heading>
            <RadioGroup onChange={onEventTypeHandle} value={eventType} colorScheme={'blue'}>
              <Stack direction='column'>
                <Radio value={EVENT_TYPE.IN_PERSON.URL}>In Person</Radio>
                <Radio value={EVENT_TYPE.ONLINE.URL}>Online</Radio>
                <Radio value={EVENT_TYPE.HYBRID.URL}>Hybrid</Radio>
              </Stack>
            </RadioGroup>

            <Heading fontSize={'14px'}>Event size</Heading>
            <RadioGroup onChange={onEventSizeHandle} value={eventSize} colorScheme={'blue'}>
              <Stack direction='column'>
                <Radio value={EVENT_SIZE.SMALL.URL}>Small</Radio>
                <Radio value={EVENT_SIZE.MEDIUM.URL}>Medium</Radio>
                <Radio value={EVENT_SIZE.LARGE.URL}>Large</Radio>
              </Stack>
            </RadioGroup>
          </VStack>
        </Stack>
      </Flex>
      {(eventType || eventSize) && (
        <BaseButton
          onClick={onResetFilters}
          variant='text'
          fontSize='sm'
          color='primary'
          label='Reset filters'
          type='submit'
          _focus={{
            boxShadow: 'none'
          }}
        />
      )}
    </Box>
  )
}
