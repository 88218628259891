import {
  Box,
  Button,
  Container,
  Flex,
  Image,
  Link,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spacer,
  Stack,
  Text
} from '@chakra-ui/react'
import Hamburger from 'hamburger-react'
import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import logo from 'src/assets/logo.svg'
import { useAuth } from 'src/user/useAuth'

export const Header = () => {
  const [isLogged, setIsLogged] = useState(false)
  const [isOpen, setIsOpen] = useState(false)
  const [isMenuOpen, setIsMenuOpen] = useState(false)
  const navigate = useNavigate()

  const { logout, user } = useAuth()
  const [windowDimension, setWindowDimension] = useState(null)

  useEffect(() => {
    setWindowDimension(window.innerWidth)
  }, [])

  useEffect(() => {
    function handleResize() {
      setWindowDimension(window.innerWidth)
    }
    window.scrollTo({
      top: 0,
      left: 0
    })
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  const isMobile = windowDimension <= 640

  const handleClick = () => {
    setIsOpen(!isOpen)
  }

  const handleMenuClick = () => {
    setIsMenuOpen(!isMenuOpen)
  }

  return (
    <Box bg='#FFFFFF' position='sticky' top={0} zIndex={1000}>
      <Container maxW='container.xl'>
        <Flex minH='90px' align='center'>
          <Box>
            <Image
              _hover={{ cursor: 'pointer' }}
              position='left'
              draggable={false}
              onClick={() => {
                navigate('/')
              }}
              src={logo}
              alt='GoConf logo'
            />
          </Box>
          <Spacer />
          <Stack direction='row' spacing={{ base: 3, sm: 5 }} align='center'>
            {!isMobile && (
              <Button
                w='95px'
                h='15px'
                onClick={() => {
                  navigate('/publish-event')
                }}
                color='#000'
                variant='link'
                style={{ textDecoration: 'none' }}
                _focus={{ boxShadow: 'none' }}
                mr='15px'
              >
                Publish an event
              </Button>
            )}
            {user ? (
              <>
                {isMobile ? (
                  <Hamburger color='#000' direction='left' size={25} onToggle={handleMenuClick} />
                ) : (
                  <>
                    <Menu closeOnSelect>
                      <MenuButton as={Button} colorScheme='whiteAlpha' variant='unstyled'>
                        <Box display='flex' gap={1}>
                          <Text color='#F26C4F'>Hi,</Text>
                          {user?.full_name}
                        </Box>
                      </MenuButton>
                      <MenuList>
                        <MenuItem
                          onClick={() => {
                            navigate('/user')
                          }}
                        >
                          Profile
                        </MenuItem>
                        <MenuItem
                          onClick={() => {
                            setIsLogged(!isLogged)
                            logout()
                          }}
                        >
                          Logout
                        </MenuItem>
                      </MenuList>
                    </Menu>
                  </>
                )}
              </>
            ) : (
              <>
                {isMobile ? (
                  <Hamburger color='#000' direction='left' size={25} onToggle={handleClick} />
                ) : (
                  <>
                    <Button
                      w='77px'
                      h='37px'
                      color='#000'
                      variant='outline'
                      _hover={{ bg: '#F26C4F' }}
                      onClick={() => navigate('/register')}
                    >
                      Sign up
                    </Button>
                    <Button
                      w='68px'
                      h='37px'
                      onClick={() => {
                        navigate('/login')
                      }}
                      bgColor='#000'
                      color='white'
                      _hover={{ bg: '#F26C4F' }}
                    >
                      Log in
                    </Button>
                  </>
                )}
              </>
            )}
          </Stack>
        </Flex>
      </Container>
      {isMobile ? (
        <Box
          display={{ base: isOpen ? 'block' : 'none', md: 'block' }}
          flexBasis={{ base: '100%', md: 'auto' }}
          sx={{ paddingBottom: 10 }}
        >
          <Stack
            spacing={8}
            align='center'
            justify={['center', 'space-between', 'flex-end', 'flex-end']}
            direction={['column', 'row', 'row', 'row']}
            pt={[4, 4, 0, 0]}
          >
            <Menu closeOnSelect>
              <MenuMobileItem to='/publish-event'>Publish an event</MenuMobileItem>
            </Menu>
            <MenuMobileItem to='/login'>Log In</MenuMobileItem>
            <MenuMobileItem to='/register'>Sign Up</MenuMobileItem>
          </Stack>
        </Box>
      ) : null}
      {isMobile ? (
        <Box
          display={{ base: isMenuOpen ? 'block' : 'none', md: 'block' }}
          flexBasis={{ base: '100%', md: 'auto' }}
          sx={{ paddingBottom: 10 }}
        >
          <Stack
            spacing={8}
            align='center'
            justify={['center', 'space-between', 'flex-end', 'flex-end']}
            direction={['column', 'row', 'row', 'row']}
            pt={[4, 4, 0, 0]}
          >
            <MenuMobileItem disabled> Hi, {user?.full_name}</MenuMobileItem>
            <MenuMobileItem to='/publish-event'>Publish an event</MenuMobileItem>
            <MenuMobileItem to='/user'>Profile</MenuMobileItem>
            <MenuMobileItem
              onClick={() => {
                setIsLogged(!isLogged)
                logout()
              }}
            >
              Logout
            </MenuMobileItem>
          </Stack>
        </Box>
      ) : null}
    </Box>
  )
}

const MenuMobileItem = ({ children, to = '/', ...rest }) => {
  return (
    <Link href={to}>
      <Text display='block' {...rest} color='black' fontSize={18}>
        {children}
      </Text>
    </Link>
  )
}
