import { Box, Tab, TabList, Tabs, Text, useMediaQuery } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useState } from 'react'
import { TIMELINE } from 'src/shared/constants'
import { NoResult } from './NoResult'
import { SearchCard } from './SearchCard'
import { SearchCardMobile } from './SearchCardMobile'
import { SearchCardSkelton } from './SearchCardSkelton'

const ResultsList = ({ conferences, loading, onTimelineHandle, timeline }) => {
  const [isMobile] = useMediaQuery('(max-width: 640px)')

  const [timelineLocal, setTimelineLocal] = useState(timeline)

  useEffect(() => {
    setTimelineLocal(timeline)
  }, [timeline])

  const returnDate = (startDate, endDate) => {
    if (dayjs(startDate).diff(dayjs(endDate), 'day') === 0) {
      return dayjs(endDate).format(`MMMM D, YYYY`)
    }
    return dayjs(startDate).format(`MMMM D - ${dayjs(endDate).format('D')}, YYYY`)
  }

  const returnLocation = (address) => {
    if (address === null) {
      return isMobile ? '' : '...'
    } else {
      return `${address.city}, ${address.country}`
    }
  }

  const returnEventType = (eventType) => {
    const e = eventType?.toLowerCase().replace('_', ' ').split(' ')
    let eventTypeUppercase = e.map((a) => a.charAt(0).toUpperCase() + a.substr(1))

    return eventTypeUppercase.toString().replace(',', ' ')
  }

  const defaultIndex = timelineLocal === TIMELINE.UPCOMING ? 0 : 1

  return (
    <>
      {timelineLocal && (
        <Tabs
          defaultIndex={defaultIndex}
          colorScheme='gc.100'
          sx={{
            alignSelf: { base: 'center', md: 'flex-start' }
          }}
        >
          <TabList>
            <Tab _focus={{ outline: 'none' }} onClick={() => onTimelineHandle(TIMELINE.UPCOMING)}>
              <Text fontSize={{ base: '12px', md: '15px' }}>Upcoming Conferences </Text>
            </Tab>

            <Tab _focus={{ outline: 'none' }} onClick={() => onTimelineHandle(TIMELINE.PAST)}>
              <Text fontSize={{ base: '12px', md: '15px' }}>Past Conferences </Text>
            </Tab>
          </TabList>
        </Tabs>
      )}

      {loading && (
        <>
          <SearchCardSkelton />
          <SearchCardSkelton />
          <SearchCardSkelton />
        </>
      )}
      {conferences &&
        conferences.length > 0 &&
        conferences.map((conference, index) => {
          return (
            <Box
              display='flex'
              justifyItems='center'
              alignItems='center'
              maxW={isMobile ? '640px' : { md: '900px', xl: '902px' }}
              minW={isMobile ? '250px' : { md: '550px', xl: '902px' }}
              backgroundColor='rgba(255, 255, 255, 1)'
              border='1px'
              borderColor='rgba(230, 230, 230, 1)'
              boxShadow='-4px 4px 8px 0px rgba(0, 0, 0, 0.07)'
              borderRadius='5px'
              key={index}
            >
              {!isMobile ? (
                <SearchCard
                  slug={conference.slug}
                  image={conference.cover?.url}
                  title={conference.title}
                  description={conference.description}
                  location={returnLocation(conference?.address)}
                  date={returnDate(conference?.start_date, conference?.end_date)}
                  type={returnEventType(conference?.event_type)}
                  isFavorite={conference.is_favorite}
                />
              ) : (
                <SearchCardMobile
                  slug={conference.slug}
                  image={conference.cover?.url}
                  title={conference.title}
                  location={returnLocation(conference?.address)}
                  date={returnDate(conference?.start_date, conference?.end_date)}
                  type={returnEventType(conference?.event_type)}
                />
              )}
            </Box>
          )
        })}
      {!loading && conferences.length === 0 && <NoResult />}
    </>
  )
}

export default ResultsList
