import { Grid, GridItem } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { ConferenceCard } from 'src/components/conference/ConferenceCard'

const temporary_dummy_conferences = [
  {
    address: {
      addressable_id: 90,
      city: 'Berlin',
      country: 'Germany'
    },
    slug: 'mlcon',
    start_date: '2022-11-27T23:00:00.000Z',
    end_date: '2022-12-01T23:00:00.000Z',
    event_type: 'HYBRID',
    cover: {
      url: 'https://api.goconf.io/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcTBCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ec708a32acc9626a961837f3f132ba698abaa6b8/297603536_1519773498473135_8889373329012009128_n.jpeg'
    }
  },
  {
    address: {
      addressable_id: 90,
      city: 'Berlin',
      country: 'Germany'
    },
    slug: 'mlcon',
    start_date: '2022-11-27T23:00:00.000Z',
    end_date: '2022-12-01T23:00:00.000Z',
    event_type: 'HYBRID',
    cover: {
      url: 'https://api.goconf.io/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcTBCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ec708a32acc9626a961837f3f132ba698abaa6b8/297603536_1519773498473135_8889373329012009128_n.jpeg'
    }
  },
  {
    address: {
      addressable_id: 90,
      city: 'Berlin',
      country: 'Germany'
    },
    slug: 'mlcon',
    start_date: '2022-11-27T23:00:00.000Z',
    end_date: '2022-12-01T23:00:00.000Z',
    event_type: 'HYBRID',
    cover: {
      url: 'https://api.goconf.io/rails/active_storage/blobs/redirect/eyJfcmFpbHMiOnsibWVzc2FnZSI6IkJBaHBBcTBCIiwiZXhwIjpudWxsLCJwdXIiOiJibG9iX2lkIn19--ec708a32acc9626a961837f3f132ba698abaa6b8/297603536_1519773498473135_8889373329012009128_n.jpeg'
    }
  }
]

const returnDate = (startDate, endDate) => {
  if (dayjs(startDate).diff(dayjs(endDate), 'day') === 0) {
    return dayjs(endDate).format(`MMMM D, YYYY`)
  }
  return dayjs(startDate).format(`MMMM DD - ${dayjs(endDate).format('D')}, YYYY`)
}

const returnLocation = (address) => {
  if (address === null) {
    return '...'
  } else {
    return `${address.city}, ${address.country}`
  }
}

const returnEventType = (eventType) => {
  const e = eventType?.toLowerCase().replace('_', ' ').split(' ')
  let eventTypeUppercase = e.map((a) => a.charAt(0).toUpperCase() + a.substr(1))

  return eventTypeUppercase.toString().replace(',', ' ')
}

export const EventsFavorites = () => {
  return (
    <Grid templateColumns={{ base: 'repeat(1,1fr)', md: 'repeat(2,1fr)' }} gap={5}>
      {temporary_dummy_conferences.map((conference, index) => (
        <GridItem w={'100%'} display='flex' justifyContent='center'>
          <ConferenceCard
            maxWidth={{ base: '75%', md: '100%' }}
            key={index}
            id={conference.slug}
            image={conference.cover?.url}
            title={conference.title}
            location={returnLocation(conference?.address)}
            date={returnDate(conference?.start_date, conference?.end_date)}
            type={returnEventType(conference?.event_type)}
          />
        </GridItem>
      ))}
    </Grid>
  )
}
