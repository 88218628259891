// import { CalendarIcon } from '@chakra-ui/icons'
import { Box, Heading, SimpleGrid, Stack } from '@chakra-ui/react'
import dayjs from 'dayjs'
import { useEffect, useMemo, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ConferencesService } from 'src/service/conferences/ConferencesService'
import { BaseButton } from '../button/BaseButton'
import { ConferenceCard } from '../conference/ConferenceCard'
import { ConferenceSkeletonCard } from '../conference/ConferenceCardSkeleton'

export const MainContent = () => {
  const [conferences, setConferences] = useState([])
  const conferencesService = useMemo(() => new ConferencesService(), [])
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  const returnDate = (startDate, endDate) => {
    if (dayjs(startDate).diff(dayjs(endDate), 'day') === 0) {
      return dayjs(endDate).format(`MMMM D, YYYY`)
    }
    return dayjs(endDate).format(`MMMM ${dayjs(startDate).format('D')} - D, YYYY`)
  }

  const returnLocation = (address) => {
    if (address === null) {
      return '...'
    } else {
      return `${address.city}, ${address.country}`
    }
  }

  const returnEventType = (eventType) => {
    const e = eventType?.toLowerCase().replace('_', ' ').split(' ')
    let eventTypeUppercase = e.map((a) => a.charAt(0).toUpperCase() + a.substr(1))

    return eventTypeUppercase.toString().replace(',', ' ')
  }

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await conferencesService.getConferences()
      if (error) {
        console.log('Error')
        return
      }

      setTimeout(() => {
        setLoading(false)
        setConferences(data)
      }, 500)
    }
    initData()
  }, [conferencesService])

  return (
    <Box display='center' justifyContent='center' alignItems='center' padding='10' bg='#f9f9f9'>
      <Stack direction={{ base: 'column', lg: 'row' }}>
        <Stack alignItems='center'>
          <Heading size={'lg'} mb={10}>
            Newly Added Conferences
          </Heading>
          <SimpleGrid columns={{ base: 1, md: 3 }} spacing={10}>
            {loading && (
              <>
                <ConferenceSkeletonCard />
                <ConferenceSkeletonCard />
                <ConferenceSkeletonCard />
                <ConferenceSkeletonCard />
                <ConferenceSkeletonCard />
                <ConferenceSkeletonCard />
              </>
            )}
            {conferences.map((conference, index) => (
              <ConferenceCard
                key={index}
                id={conference.slug}
                image={conference.cover?.url}
                title={conference.title}
                location={returnLocation(conference?.address)}
                date={returnDate(conference?.start_date, conference?.end_date)}
                type={returnEventType(conference?.event_type)}
              />
            ))}
          </SimpleGrid>
          <BaseButton
            width={{ base: 'full', md: 'auto' }}
            label='Explore more conferences'
            onClick={() => navigate('/explore')}
            color='#F26C4F'
            sx={{ marginTop: '3rem !important' }}
          />
        </Stack>
      </Stack>
    </Box>
  )
}
