import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Checkbox,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  HStack,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import axios from 'axios'
import { useState } from 'react'
import { Form, useField } from 'react-final-form'
import { useLocation, useNavigate } from 'react-router-dom'
import LogoBlack from 'src/assets/logo-black.svg'
import { LoginLayout } from 'src/components/login/LoginLayout'
import { SpinnerCard } from 'src/components/login/SpinnerCard'
import useValidationSchema from 'src/shared/utils/use-validation-schema'
import * as yup from 'yup'

export const RegisterPage = (props) => {
  const location = useLocation()
  const [show, setShow] = useState(false)
  const navigate = useNavigate()

  const [agree, setAgree] = useState(false)
  const [isCreating, setIsCreating] = useState(false)
  const [registered, setRegistered] = useState(false)

  const [isMobile] = useMediaQuery('(max-width: 640px)')

  const showPasswordHandler = () => {
    setShow(!show)
  }

  const validationSchema = yup.object().shape({
    first_name: yup
      .string()
      .label('First name')
      .min(2, 'First name too short!')
      .required('Please enter your first name'),
    last_name: yup.string().label('Last name').min(2, 'Last name too short!').required('Please enter your last name'),
    email: yup.string().email().label('Email').required('Please enter your email'),
    password: yup.string().min(6, 'Password too short!').required('Please enter your password')
  })

  const validate = useValidationSchema(validationSchema)

  const onSubmit = async (values) => {
    const request_url = process.env.REACT_APP_API_URL + '/members'

    if (agree) {
      setIsCreating(true)
      axios
        .post(request_url, {
          first_name: values.first_name,
          last_name: values.last_name,
          email: values.email,
          password: values.password,
          is_premium: false
        })
        .then((response) => {
          setIsCreating(false)
          setRegistered(true)
        })
        .catch((err) => {
          console.log(err)
          //error to be shown
          setIsCreating(false)
          alert(err)
        })
    }
  }

  const onCheckboxChange = (event) => {
    setAgree(event.target.checked)
  }

  if (isCreating) {
    return (
      <LoginLayout>
        <SpinnerCard text='registering' />
      </LoginLayout>
    )
  }

  if (registered) {
    return (
      <LoginLayout>
        <SpinnerCard text='registered' />
      </LoginLayout>
    )
  }

  return (
    <LoginLayout prop={location.pathname}>
      <Box>
        <VStack
          display={'flex'}
          alignItems={'start'}
          w={isMobile ? undefined : { base: '210px', md: '350px', lg: '430px' }}
          paddingBottom={'10px'}
          paddingTop={'10px'}
        >
          {isMobile && <Image src={LogoBlack} height={'25px'} marginBottom={'65px'} onClick={() => navigate('/')} />}
          <Stack paddingBottom={'15px'}>
            <Heading color={'rgba(13, 51, 84, 1)'} fontWeight={'bold'} fontSize={'35px'}>
              Sign up
            </Heading>
            <Flex fontSize={'16px'} color={'#333333'}>
              <Text fontSize='md' color='GrayText'>
                {`Already have an account? `}
                <Link color={'rgba(0, 178, 244, 1)'} onClick={() => navigate('/login')}>
                  Sign in
                </Link>
              </Text>
            </Flex>
          </Stack>
          <Form
            onSubmit={onSubmit}
            validate={validate}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <Box as='form' w={'100%'} onSubmit={handleSubmit}>
                <InputControl name='first_name' label='First Name' placeholder={'John'} />
                <InputControl name='last_name' label='Last Name' placeholder={'Doe'} />
                <InputControl name='email' label='Email' placeholder={'example@goconf.io'} />
                <InputPasswordControl
                  name='password'
                  label='Password'
                  placeholder={'Password'}
                  show={show}
                  showPassword={showPasswordHandler}
                />
                <Text fontSize='xs' color='GrayText'>
                  Use 6 more characters with a mix of letters, numbers &amp; symbols
                </Text>
                <HStack>
                  <Checkbox onChange={(e) => onCheckboxChange(e)} value={agree} size='sm'></Checkbox>
                  <Text fontSize='sm' color='GrayText'>
                    I agree to the
                    {/* <Link color={'rgba(0, 178, 244, 1)'}>Term of Service</Link> and */}{' '}
                    <Link href='https://goconf.io/privacy' color={'rgba(0, 178, 244, 1)'}>
                      Privacy Policy
                    </Link>
                  </Text>
                </HStack>
                <Button
                  type='submit'
                  top={10}
                  size={'md'}
                  height={'40px'}
                  width={'full'}
                  bgColor={'rgba(242, 108, 79, 1)'}
                  color={'rgba(255, 255, 255, 1)'}
                  disabled={!agree}
                >
                  Sign Up
                </Button>
              </Box>
            )}
          />
        </VStack>
      </Box>
    </LoginLayout>
  )
}

const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, placeholder }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name} fontSize={'16px'} fontWeight={'500'}>
        {label}
      </FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={placeholder}
        _placeholder={{ color: 'rgba(0, 0, 0, 0.36)' }}
        border={'1px'}
        borderRadius={'6px'}
        fontWeight={400}
      />
      <Error name={name} />
    </Control>
  )
}

const InputPasswordControl = ({ name, label, placeholder, show, showPassword }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>
      <FormLabel htmlFor={name} fontSize={'16px'} fontWeight={'500'}>
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          {...input}
          isInvalid={meta.error && meta.touched}
          id={name}
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          _placeholder={{ color: 'rgba(0, 0, 0, 0.36)' }}
          border={'1px'}
          borderRadius={'6px'}
          fontWeight={400}
        />
        <InputRightElement>
          {show ? (
            <ViewOffIcon size={'lg'} color={'rgba(222, 222, 222, 1)'} onClick={showPassword} />
          ) : (
            <ViewIcon size={'lg'} color={'rgba(222, 222, 222, 1)'} onClick={showPassword} />
          )}
        </InputRightElement>
      </InputGroup>
      <Error name={name} />
    </Control>
  )
}
