import { Button, Flex, Heading, Image, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import React from 'react'
import { useNavigate } from 'react-router-dom'
import BrokenLogo from 'src/assets/broken-logo.svg'
import { Footer } from 'src/components/footer/Footer'
import { Header } from 'src/components/header/Header'

export const NotFoundPage = () => {
  const [isMobile] = useMediaQuery('(max-width: 640px)')
  const navigate = useNavigate()

  return (
    <>
      <Header />
      <Flex justify='center' align='center' minH={'80vh'}>
        <Stack direction={isMobile ? 'column' : 'row'} width={'full'} w={'70%'} justifyContent='space-between'>
          {isMobile ? <Image src={BrokenLogo} w={350} mb={10} /> : undefined}
          <Stack h={280} justifyContent='space-between' mb={isMobile ? 50 : undefined}>
            <Heading fontSize={isMobile ? '22px' : '42px'}>Oh, no! You’ve entered the void.</Heading>
            <Text w={isMobile ? 250 : 650} fontSize={isMobile ? '14px' : '20px'}>
              The page you’re looking for does not exist. Please double check the URL or return to the homepage.
            </Text>
            <Button
              size={'md'}
              height={'48px'}
              width={'250px'}
              bgColor={'rgba(242, 108, 79, 1)'}
              color={'rgba(255, 255, 255, 1)'}
              onClick={() => navigate('/')}
            >
              Take me to the homepage
            </Button>
          </Stack>
          {!isMobile ? <Image src={BrokenLogo} w={350} /> : undefined}
        </Stack>
      </Flex>
      <Footer />
    </>
  )
}
