import axios from 'axios'
import { SESSION } from 'src/user/useAuth'

export class HttpService {
  constructor() {
    const token = window.localStorage.getItem(SESSION)
    const service = axios.create({
      baseURL: process.env.REACT_APP_API_URL,
      headers: token
        ? {
            Authorization: `Bearer ${token}`
          }
        : {}
    })

    service.interceptors.response.use(this.handleSuccess, this.handleError)

    this.service = service
  }

  handleSuccess(response) {
    return {
      data: response.data,
      error: null
    }
  }

  handleError(error) {
    return {
      data: null,
      error: { message: error.response.data, status: error.response.status }
    }
  }

  get(...args) {
    return this.service.get(...args)
  }

  post(...args) {
    return this.service.post(...args)
  }

  put(...args) {
    return this.service.put(...args)
  }

  patch(...args) {
    return this.service.patch(...args)
  }

  delete(...args) {
    return this.service.delete(...args)
  }
}
