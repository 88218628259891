import { Avatar, Box, Image, Spinner, Text } from '@chakra-ui/react'

const UserHeader = ({ name, lastName, profile, initialFetch }) => {
  return (
    <Box
      borderRadius={5}
      w='100%'
      h='150px'
      display='flex'
      px={8}
      py={8}
      alignItems='center'
      boxShadow=' -4px 4px 8px rgba(0, 0, 0, 0.07)'
      border={'1px solid #eee'}
    >
      {!initialFetch ? (
        profile ? (
          <Image borderRadius='full' boxSize='110px' src={profile.url} alt='Profile Picture' mr={6} />
        ) : (
          <Avatar size={'xl'} name={`${name} ${lastName}`} />
        )
      ) : (
        <Spinner boxSize='110' />
      )}
      <Text fontSize='3xl' fontWeight={500}>
        {name} {lastName}
      </Text>
    </Box>
  )
}

export default UserHeader
