import { Modal, ModalContent, ModalOverlay, Spinner } from '@chakra-ui/react'

const LoadingModal = ({ onClose, isOpen }) => {
  return (
    <Modal onClose={onClose} isOpen={isOpen} isCentered>
      <ModalOverlay />
      <ModalContent justifyContent='center' alignItems='center' backgroundColor='transparent' boxShadow='none'>
        <Spinner color='orange' size='xl' />
      </ModalContent>
    </Modal>
  )
}

export default LoadingModal
