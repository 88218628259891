import { Box, Divider, Heading, Image, Link, SimpleGrid, useMediaQuery } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import AppleApp from 'src/assets/appstore.svg'
import { useAuth } from 'src/user/useAuth'
import ArrowUp from '../../assets/arrowUp.svg'
import Copyright from '../../assets/copyright.svg'
import Help from '../../assets/help.svg'
import Logo from '../../assets/logo.svg'
import FooterItem from './FooterItem'
import { footerData } from './mocks/footerData'

export const Footer = () => {
  const { user } = useAuth()
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 640px)')

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    })
  }

  const checkedFooterData = user ? footerData.footer.filter((f) => f.show === 'all') : footerData.footer

  return (
    <Box
      sx={{
        border: '1px solid #030B30',
        direction: 'column',
        alignItems: 'center',
        background: '#030B30',
        paddingRight: { base: '16px', md: '75px', lg: '135px' },
        paddingLeft: { base: '16px', md: '75px', lg: '135px' },
        paddingTop: { base: '15px', md: '27px', lg: '40px' },
        paddingBottom: { base: '15px', md: '27px', lg: '40px' }
      }}
    >
      <Box sx={{ display: 'flex', justifyContent: 'space-between', marginTop: '40px' }}>
        <Image
          onClick={() => {
            navigate('/')
          }}
          _hover={{ cursor: 'pointer' }}
          src={Logo}
          width='100px'
          height='15px'
          alt='Logo'
        />
        <Box sx={{ display: 'flex', cursor: 'pointer' }} onClick={scrollToTop}>
          <Heading sx={{ fontSize: '14px', color: '#FFFFFF', marginRight: '10px', fontWeight: 600 }}>
            Return to Top
          </Heading>
          <Image src={ArrowUp} alt='To Top' />
        </Box>
      </Box>

      <Divider
        style={{
          marginBottom: isMobile ? '10px' : '34px',
          marginTop: '15px',
          borderColor: 'rgba(255, 255, 255, 0.5)'
        }}
      />

      <Box display='flex' justifyContent='space-between' alignItems='center'>
        <SimpleGrid columns={isMobile && '2'}>
          {checkedFooterData.map((item, index) => (
            <Box
              key={index}
              sx={{
                marginBottom: footerData.footer.length - 1 === index ? '0px' : { base: '13px', md: '25px', lg: '38px' }
              }}
            >
              <FooterItem title={item.title} items={item.items} />
            </Box>
          ))}
        </SimpleGrid>
        <Box mb='55px'>
          <Heading fontSize='15px' color='#f26c4f' marginBottom='15px' textAlign='center'>
            TRY THE APP
          </Heading>
          <Link href='https://apps.apple.com/mk/app/goconf/id1662541634' isExternal>
            <Image src={AppleApp} alt='Apple App' />
          </Link>
        </Box>
      </Box>
      <Divider
        style={{
          marginBottom: isMobile ? '12px' : '15px',
          marginTop: isMobile ? '17px' : '34px',
          borderColor: 'rgba(255, 255, 255, 0.5)'
        }}
      />

      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: isMobile ? 'center' : '' }}>
        <Image src={Copyright} alt='copyright' marginRight='7px' />
        {isMobile && (
          <Heading
            sx={{
              fontSize: '11px',
              color: '#FFFFFF',
              fontWeight: 400
            }}
          >
            GoConf 2024
          </Heading>
        )}
        {!isMobile &&
          footerData.privacy.map((item, index) => (
            <Heading
              key={index}
              sx={{
                fontSize: '11px',
                color: '#FFFFFF',
                marginRight: footerData.privacy.length - 1 === index ? '0px' : '20px',
                fontWeight: 400
              }}
            >
              {item.title}
            </Heading>
          ))}
        {isMobile && <Image src={Help} alt='help' marginLeft='7px' />}
      </Box>
    </Box>
  )
}
