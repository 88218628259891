import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Stack,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import axios from 'axios'
import { useState } from 'react'
import { Form, useField } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import LogoBlack from 'src/assets/logo-black.svg'
import useValidationSchema from 'src/shared/utils/use-validation-schema'
import { useAuth } from 'src/user/useAuth'
import * as yup from 'yup'
import { SpinnerCard } from './SpinnerCard'

export const LoginCard = (props) => {
  const [show, setShow] = useState(false)
  const [isLoading, setIsLoading] = useState(false)

  const [isMobile] = useMediaQuery('(max-width: 640px)')
  const navigate = useNavigate()

  const { login } = useAuth()

  const showPasswordHandler = () => {
    setShow(!show)
  }

  const validationSchema = yup.object().shape({
    email: yup.string().email().label('Email').required('Please enter your email'),
    password: yup.string().min(6, 'Password too short!').required('Please enter your password')
  })

  const validate = useValidationSchema(validationSchema)

  if (isLoading) {
    return <SpinnerCard text='logIn' />
  }

  const onSubmit = async (values) => {
    const request_url = process.env.REACT_APP_API_URL + '/auth/members/login'

    setIsLoading(true)

    axios
      .post(request_url, {
        email: values.email,
        password: values.password
      })
      .then((response) => {
        login(response.data.access_token)
      })
      .catch((err) => {
        setIsLoading(false)
      })
  }

  return (
    <Box display='flex' alignItems='start' w='full'>
      <VStack
        alignItems='start'
        w={isMobile ? '800px' : { md: '400px', lg: '900px' }}
        marginLeft={{ lg: '25px' }}
        padding={isMobile ? '20px' : { md: '50px', lg: '220px' }}
        display='flex'
        paddingBottom='10px'
        paddingTop='10px'
      >
        {isMobile && <Image src={LogoBlack} height={'25px'} marginBottom={'65px'} onClick={() => navigate('/')} />}
        <Stack paddingBottom={'15px'} display='flex' alignItems='start'>
          <Heading color={'rgba(13, 51, 84, 1)'} fontWeight={'bold'} fontSize={'35px'}>
            Log In
          </Heading>
          <Flex fontSize={'16px'} color={'#333333'}>
            <Text color='GrayText' fontSize='md'>
              {` Don’t have an account yet`}
              <Link onClick={() => navigate('/register')} color={'rgba(0, 178, 244, 1)'}>
                {` Sign Up `}
              </Link>
              now
            </Text>
          </Flex>
        </Stack>
        <Form
          onSubmit={onSubmit}
          validate={validate}
          render={({ handleSubmit, form, submitting, pristine, values }) => (
            <Box as='form' w={'100%'} onSubmit={handleSubmit}>
              <InputControl name='email' label='Email' placeholder={'example@goconf.io'} />
              <InputPasswordControl
                name='password'
                label='Password'
                placeholder={'Password'}
                show={show}
                showPassword={showPasswordHandler}
              />
              <Stack direction={'column'} display={'flex'} alignItems={'center'} width={'full'}>
                <Button
                  type='submit'
                  top={5}
                  size={'md'}
                  height={'40px'}
                  width={'full'}
                  bgColor={'rgba(242, 108, 79, 1)'}
                  color={'rgba(255, 255, 255, 1)'}
                >
                  Log In
                </Button>
                <Text
                  onClick={() => navigate('forgot-password')}
                  color={'rgba(0, 178, 244, 1)'}
                  fontSize={'14px'}
                  paddingTop={'23px'}
                  _hover={{ cursor: 'pointer' }}
                >
                  Forgot Password?
                </Text>
              </Stack>
            </Box>
          )}
        />
      </VStack>
    </Box>
  )
}

const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, placeholder }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4} pb={'10px'}>
      <FormLabel htmlFor={name} fontSize={'16px'} fontWeight={'500'}>
        {label}
      </FormLabel>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={placeholder}
        _placeholder={{ color: 'rgba(0, 0, 0, 0.36)' }}
        border={'1px'}
        borderRadius={'6px'}
        fontWeight={400}
      />
      <Error name={name} />
    </Control>
  )
}

const InputPasswordControl = ({ name, label, placeholder, show, showPassword }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4} pb={'10px'}>
      <FormLabel htmlFor={name} fontSize={'16px'} fontWeight={'500'}>
        {label}
      </FormLabel>
      <InputGroup>
        <Input
          {...input}
          isInvalid={meta.error && meta.touched}
          id={name}
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          _placeholder={{ color: 'rgba(0, 0, 0, 0.36)' }}
          border={'1px'}
          borderRadius={'6px'}
          fontWeight={400}
        />
        <InputRightElement>
          {show ? (
            <ViewOffIcon size={'lg'} color={'rgba(222, 222, 222, 1)'} onClick={showPassword} />
          ) : (
            <ViewIcon size={'lg'} color={'rgba(222, 222, 222, 1)'} onClick={showPassword} />
          )}
        </InputRightElement>
      </InputGroup>
      <Error name={name} />
    </Control>
  )
}
