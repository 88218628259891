import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightElement,
  Link,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import axios from 'axios'
import { useState } from 'react'
import { Form, useField } from 'react-final-form'
import { useNavigate, useParams } from 'react-router-dom'
import LogoBlack from 'src/assets/logo-black.svg'
import { LoginLayout } from 'src/components/login/LoginLayout'
import useValidationSchema from 'src/shared/utils/use-validation-schema'
import * as yup from 'yup'
import ConfirmationScreen from './PasswordChanged'

export const ResetPassword = () => {
  const [loading, setLoading] = useState(false)
  const [show, setShow] = useState(false)
  const [showConfirm, setShowConfirm] = useState(false)
  const [passwordCheck, setPasswordCheck] = useState(false)
  const [isMobile] = useMediaQuery('(max-width: 640px)')
  const navigate = useNavigate()
  const params = useParams()

  const validationSchema = yup.object().shape({
    password: yup.string().min(6, 'Password too short!').required('Please enter your password'),
    confirm_password: yup.string().oneOf([yup.ref('password'), null], 'Passwords must match')
  })

  const validate = useValidationSchema(validationSchema)

  const onSubmit = async (values) => {
    const token = params.token
    if (values.password === values.confirm_password && values.password.trim().length >= 6) {
      setLoading(true)
      axios
        .post(process.env.REACT_APP_API_URL + '/members/reset_password', {
          token,
          new_password: values.password
        })
        .then((res) => {
          setPasswordCheck(true)
        })
        .catch((err) => {
          console.log(err)
          setLoading(false)
        })
    }
  }

  return (
    <LoginLayout>
      <Flex justify='center' align='center' width='100%' height='100vh'>
        {!passwordCheck ? (
          <VStack width={isMobile ? '80%' : '50%'} py={8} px={5}>
            {isMobile && (
              <Image
                src={LogoBlack}
                height={'25px'}
                marginBottom={'100px'}
                marginTop={-20}
                onClick={() => navigate('/')}
              />
            )}
            <Heading
              size={isMobile ? 'lg' : 'md'}
              textAlign={!isMobile ? 'center' : 'start'}
              w={isMobile ? '100%' : 'fit-content'}
            >
              New Password
            </Heading>
            <Text fontSize='sm' textAlign={!isMobile ? 'center' : 'start'} w={isMobile ? '100%' : 'fit-content'}>
              Please create a new secure password that you’ll remember to log in in the future.
            </Text>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <Box as='form' w={'100%'} onSubmit={handleSubmit}>
                  <InputPasswordControl
                    name='password'
                    label='Password'
                    placeholder={'New Password'}
                    show={show}
                    showPassword={() => setShow(!show)}
                  />
                  <InputPasswordControl
                    name='confirm_password'
                    label='Confirm Password'
                    placeholder={'Confirm Password'}
                    show={showConfirm}
                    showPassword={() => setShowConfirm(!showConfirm)}
                  />
                  <Button
                    type='submit'
                    isLoading={loading}
                    w='100%'
                    colorScheme={'orange'}
                    onClick={() => onSubmit(values)}
                  >
                    Continue
                  </Button>
                </Box>
              )}
            />
            <Text textAlign='center' fontSize='md'>
              Don’t have an account yet?&nbsp;
              <Link onClick={() => navigate('/register')} fontWeight='600' color='blue.400'>
                Sign Up
              </Link>
              &nbsp;now.
            </Text>
          </VStack>
        ) : (
          <ConfirmationScreen />
        )}
      </Flex>
    </LoginLayout>
  )
}

const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputPasswordControl = ({ name, label, placeholder, show, showPassword }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4}>
      <InputGroup>
        <Input
          {...input}
          isInvalid={meta.error && meta.touched}
          id={name}
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          _placeholder={{ color: 'rgba(0, 0, 0, 0.36)' }}
          border={'1px'}
          borderRadius={'6px'}
          fontWeight={400}
        />
        <InputRightElement>
          {show ? (
            <ViewOffIcon size={'lg'} color={'rgba(222, 222, 222, 1)'} onClick={showPassword} />
          ) : (
            <ViewIcon size={'lg'} color={'rgba(222, 222, 222, 1)'} onClick={showPassword} />
          )}
        </InputRightElement>
      </InputGroup>
      <Error name={name} />
    </Control>
  )
}
