import { Flex, Heading, Stack, Text } from '@chakra-ui/react'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'

//TEMPORARY
const ComingSoon = () => {
  return (
    <Flex h={'130vh'} justifyContent='space-between' direction={'column'}>
      <Header />
      <Stack justifyContent='space-between'>
        <Heading textAlign={'center'}>Coming Soon.</Heading>
        <Text textAlign={'center'}>This Page will be available shortly</Text>
      </Stack>
      <Footer />
    </Flex>
  )
}

export default ComingSoon
