import { Outlet, Route, Routes } from 'react-router-dom'
import { ROUTES } from '.'

export const AllRoutes = () => {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <>
          {Object.values(ROUTES).map(({ path, component: Component }) => (
            <Route key={path} path={path} exact element={<Component />} />
          ))}
        </>
      </Route>
    </Routes>
  )
}
