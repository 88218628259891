import { EmailIcon, ExternalLinkIcon } from '@chakra-ui/icons'
import {
  Button,
  FormControl,
  FormLabel,
  Image,
  Input,
  InputGroup,
  InputLeftElement,
  Stack,
  Text,
  Textarea,
  VStack
} from '@chakra-ui/react'
import { useRef, useState } from 'react'
import user from 'src/assets/user.svg'

const CareerPageForm = ({ onSubmit }) => {
  const [isLoading, setIsLoading] = useState(false)
  const [file, setFile] = useState('')
  const [fullName, setFullName] = useState('')
  const [emailAddress, setEmailAddress] = useState('')
  const [message, setMessage] = useState('')
  const hiddenInput = useRef(null)

  const uploadFunction = () => {
    hiddenInput.current.click()
  }

  const fileChangeHandler = (e) => {
    let currentFile = e.target.files[0]
    if (currentFile) {
      setFile(currentFile)
    }
  }

  return (
    <Stack
      borderRadius='10px'
      border={{ base: '2px solid #EFEFEF' }}
      bg={{ md: '#eee' }}
      Stack
      w={'100%'}
      my={0}
      mx={2}
    >
      <VStack height='100%' p={3}>
        <FormControl>
          <FormLabel htmlFor='full-name'>Name</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents='none' children={<Image w={3} h={3} src={user} />} />
            <Input
              isRequired
              id='full-name'
              type='text'
              placeholder='Full Name'
              onChange={(e) => setFullName(e.target.value)}
              value={fullName}
            />
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='email'>Email</FormLabel>
          <InputGroup>
            <InputLeftElement pointerEvents='none' children={<EmailIcon />} />
            <Input
              isRequired
              id='email'
              type='email'
              placeholder='Email Address'
              value={emailAddress}
              onChange={(e) => setEmailAddress(e.target.value)}
            />
          </InputGroup>
        </FormControl>
        <FormControl>
          <FormLabel htmlFor='text-area'>Message</FormLabel>
          <Textarea
            placeholder='Hello GoConf...'
            id='text-area'
            minH={'120px'}
            maxH={'120px'}
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></Textarea>
        </FormControl>
        <Stack direction={{ base: 'column', md: 'row' }} py={5} w={'100%'}>
          <Stack
            h='fit-content'
            my={2}
            minW={'75%'}
            direction='row'
            justifyContent={{ base: 'center', md: 'start' }}
            alignItems='center'
          >
            <Text fontWeight={'semibold'} fontSize='md'>
              Resume*
            </Text>
            <Text fontSize='xs' color='#999'>
              {file ? file.name : `(PDF,JPG,JPEG,Word.dox)`}
            </Text>
          </Stack>
          <Stack
            my={2}
            w={{ base: '100%', md: '25%' }}
            justifyContent={{ base: 'center', md: 'end' }}
            alignItems='center'
          >
            <Button height='100%' onClick={uploadFunction} variant='link'>
              <ExternalLinkIcon color={'#00B2F4'} />
              <Text color={'#00B2F4'} fontSize='md' pl={2}>
                Upload
              </Text>
            </Button>
          </Stack>

          <Input ref={hiddenInput} type='file' display='none' onChange={(e) => fileChangeHandler(e)} />
        </Stack>
        <Button
          isLoading={isLoading}
          onClick={() => {
            setIsLoading(true)
            setTimeout(() => {
              if (
                fullName.trim() !== '' &&
                emailAddress.includes('@') &&
                emailAddress.includes('.com') &&
                message.trim() !== '' &&
                file
              ) {
                // const test = new FormData()

                // test.append('full_name', data.full_name)
                // test.append('email', data.email)
                // test.append('message', data.message)
                // test.append('cv', data.cv)

                onSubmit({
                  full_name: fullName,
                  email: emailAddress,
                  message,
                  cv: file
                })
              } else {
                setIsLoading(false)
              }
            }, 300)
          }}
          size={'lg'}
          width='100%'
          colorScheme={'orange'}
        >
          Submit
        </Button>
      </VStack>
    </Stack>
  )
}

export default CareerPageForm
