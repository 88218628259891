import { Box, Center, Heading, SimpleGrid } from '@chakra-ui/react'
import React from 'react'
import Barcelona from 'src/assets/images/barcelona.jpg'
import Krakow from 'src/assets/images/krakow.jpg'
import Sofia from 'src/assets/images/sofia.jpg'
import Stuttgart from 'src/assets/images/stuttgart.jpg'

import { PopularLocationCard } from './PopularLocationCard'

export const PopularLocations = () => {
  return (
    <Box bg='#F9F9F9' sx={{ padding: 10, paddingBottom: 20 }}>
      <Center>
        <Heading as='h2' sx={{ fontSize: '22px', color: '#030B30', fontWeight: 700 }}>
          Popular Locations
        </Heading>
      </Center>
      <Center>
        <SimpleGrid columns={{ base: 2, md: 4 }} spacing={10} marginTop='45px'>
          <PopularLocationCard title='Sofia' image={Sofia} />
          <PopularLocationCard title='Barcelona' image={Barcelona} />
          <PopularLocationCard title='Krakow' image={Krakow} />
          <PopularLocationCard title='Stuttgart' image={Stuttgart} />
        </SimpleGrid>
      </Center>
    </Box>
  )
}
