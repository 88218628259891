import { HttpService } from '..'

export class MemberService extends HttpService {
  constructor() {
    super()
    this.path = '/members'
    this.verify_path = '/verify_member'
  }

  current() {
    return this.get(`current_member`)
  }

  updateMember(memberId, member) {
    return this.put(`${this.path}/${memberId}`, member)
  }

  verifyMember = (token) => {
    return this.get(`${this.verify_path}/${token}`)
  }
}
