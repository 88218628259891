import Facebook from '../../../assets/facebookLogo.svg'
import Instagram from '../../../assets/instagramLogo.svg'
import LinkedIn from '../../../assets/linkedInLogo.svg'

export const footerData = {
  footer: [
    {
      title: 'About',
      items: [
        { title: 'Privacy', link: 'privacy' },
        { title: 'Terms of Use', link: 'terms-of-use' },
        { title: 'Careers', link: 'careers' },
        { title: 'Become a partner', link: 'become-a-partner' }
      ],
      show: 'all'
    },
    {
      title: 'Contact',
      items: [{ title: 'Customer Service', link: 'customer-service' }],
      show: 'all'
    },
    {
      title: 'Connect',
      items: [
        { title: 'Facebook', icon: Facebook, link: 'https://www.facebook.com/goconfio', isExternal: true },
        { title: 'Instagram', icon: Instagram, link: 'https://www.instagram.com/goconf.io/', isExternal: true },
        { title: 'LinkedIn', icon: LinkedIn, link: 'https://mk.linkedin.com/company/goconf', isExternal: true }
      ],
      show: 'all'
    },
    {
      title: 'Join US',
      items: [
        { title: 'Sign Up', link: 'register' },
        { title: 'Log In', link: 'login' }
      ],
      show: 'public'
    }
  ],

  privacy: [
    {
      title: 'All rights reserved. GoConf 2024'
    },
    {
      title: 'Privacy Policy'
    },
    {
      title: 'Terms & Conditions'
    }
  ]
}
