import { Box, Flex, Stack } from '@chakra-ui/react'
import axios from 'axios'
import { useState } from 'react'
import CareersBanner from 'src/assets/images/CareersPageImg.jpg'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'
import CareerPageInfo from './CareerInfo'
import CareerPageForm from './CareersForm'
import CareersSubmit from './CareersSubmit'
export const CareersPage = () => {
  const [submit, setSubmit] = useState(false)

  const onSubimitDataHandler = (formData) => {
    const test = new FormData()

    test.append('full_name', formData.full_name)
    test.append('email', formData.email)
    test.append('message', formData.message)
    test.append('cv', formData.cv)

    var obj = {}
    for (var key of test.keys()) {
      obj[key] = test.get(key)
    }

    axios({
      method: 'POST',
      url: process.env.REACT_APP_API_URL + '/users/careers',
      data: formData,
      headers: {
        'Content-Type': 'multipart/form-data'
      }
    })

    setSubmit(true)
  }

  return (
    <>
      <Header />
      <Flex
        zIndex={-1}
        w={'full'}
        h={{ base: '13vh', md: '20vh' }}
        backgroundImage={CareersBanner}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
        columns={{ base: 1, md: 3 }}
      >
        <Box h='100%' w='100%' bgGradient={'linear(to-b, rgba(3, 11, 48, 0.7), rgba(3, 11, 48, 0.4))'}></Box>
      </Flex>

      <Flex
        transform={{ base: 'translateY(-100px)', md: 'translateY(-50px)' }}
        justify='center'
        align='center'
        minH={'90vh'}
        m='auto'
        width={'100%'}
      >
        <Stack
          w={{ lg: '75%' }}
          borderRadius='5px'
          my={6}
          px={4}
          py={8}
          bg={{ md: '#030B30' }}
          direction={{ base: 'column', md: 'row' }}
        >
          {!submit ? (
            <>
              <CareerPageInfo />
              <CareerPageForm onSubmit={onSubimitDataHandler} />
            </>
          ) : (
            <CareersSubmit />
          )}
        </Stack>
      </Flex>
      <Footer />
    </>
  )
}
