import { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { MemberService } from 'src/service/members/MemberService'
import { LoginLayout } from '../login/LoginLayout'
import { SpinnerCard } from '../login/SpinnerCard'

export const VerifyUser = () => {
  const { token } = useParams()
  const memberService = useMemo(() => new MemberService(), [])
  const [error, setError] = useState(false)
  const [verified, setVerified] = useState(false)
  const navigate = useNavigate()

  useEffect(() => {
    const init = async () => {
      const { error, data } = await memberService.verifyMember(token)
      if (error || data.includes('does not exist')) {
        setError(true)
        console.log('Error', error)
        return
      }

      setVerified(true)
      setTimeout(() => {
        navigate('/login')
      }, 2000)
    }
    init()
  }, [token, memberService, navigate])

  return (
    <LoginLayout>
      <SpinnerCard text={error ? 'errorVerifying' : verified ? 'verified' : 'verifying'} />
    </LoginLayout>
  )
}
