import { AtSignIcon, CalendarIcon, ViewIcon } from '@chakra-ui/icons'
import { Box, Heading, Image, Stack } from '@chakra-ui/react'
import { Link } from 'react-router-dom'

export const SearchCardMobile = ({ slug, image, title, location, date, type }) => {
  return (
    <Box w={'310px'} margin={'15px'}>
      <Link to={`/conference/${slug}`}>
        <Stack direction={'row'} spacing={'15px'}>
          <Image src={image} w={'90px'} h={'90px'} borderRadius={'5px'} objectFit='cover' />
          <Box display={'flex'}>
            <Stack display={'flex'} justifyContent={'space-evenly'}>
              <Stack>
                <Heading fontSize={'14px'} fontWeight={'semibold'}>
                  {title}
                </Heading>
              </Stack>
              <Stack>
                <Stack direction={'column'} spacing={'1'}>
                  <Stack display={'flex'} alignItems={'center'} direction={'row'}>
                    <AtSignIcon color={'#F58972'} />
                    <Box paddingLeft={'7px'} fontSize={'12px'}>
                      {location.substring(0, 22)}...
                    </Box>
                  </Stack>
                  <Stack display={'flex'} alignItems={'center'} direction={'row'}>
                    <CalendarIcon color={'#F58972'} />
                    <Box paddingLeft={'7px'} fontSize={'12px'}>
                      {date}
                    </Box>
                  </Stack>
                  <Stack display={'flex'} alignItems={'center'} direction={'row'}>
                    <ViewIcon color={'#F58972'} />
                    <Box paddingLeft={'7px'} fontSize={'12px'}>
                      {type}
                    </Box>
                  </Stack>
                </Stack>
              </Stack>
            </Stack>
          </Box>
        </Stack>
      </Link>
    </Box>
  )
}
