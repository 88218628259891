import { Box, Heading, Image, useMediaQuery } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import noCover from 'src/assets/images/nocover.jpg'

export const PopularLocationCard = ({ image, title, ...props }) => {
  const [isMobile] = useMediaQuery('(max-width: 640px)')

  return (
    <Box maxW='345px' w='full' boxShadow='xl' rounded='md' overflow='hidden' bgColor='#030B30'>
      <Link to={'/'}>
        <Box bg={'whiteAlpha.100'}>
          {image !== null ? (
            <Image src={image} h='350px' maxH={isMobile ? '250px' : '350px'} w={'270px'} objectFit='cover' />
          ) : (
            <Image src={noCover} h={'350px'} w={'270px'} objectFit='cover' />
          )}
        </Box>
        <Heading size={'md'} color='#fff' textAlign='center' py={2}>
          {title}
        </Heading>
      </Link>
    </Box>
  )
}
