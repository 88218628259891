import React from 'react'
import { Banner } from 'src/components/banner/Banner'
import { Benchmarks } from 'src/components/benchmarks/Benchmarks'
import { Footer } from 'src/components/footer/Footer'
import { Header } from 'src/components/header/Header'
import { MainContent } from 'src/components/main-content/MainContent'
import { Partners } from 'src/components/partners/Partners'
import { PopularLocations } from 'src/components/popular-locations/PopularLocations'

export const HomePage = () => {
  return (
    <>
      <Header />
      <Banner />
      <MainContent />
      <Benchmarks />
      <PopularLocations />
      <Partners />
      <Footer />
    </>
  )
}
