import { Box, Center, Grid, GridItem, Heading, Image, Link } from '@chakra-ui/react'
import React from 'react'
import AmazonLogo from '../../assets/aws-logo.svg'
import fitrLogo from '../../assets/fitrLogo.svg'
import rotteraLogo from '../../assets/rotteraLogo.svg'

export const Partners = () => {
  return (
    <Box bg='#fff' paddingY='70px'>
      <Center marginTop='-10' marginBottom={10}>
        <Heading as='h2' sx={{ fontSize: '22px', color: '#030B30', fontWeight: 600 }}>
          Partners
        </Heading>
      </Center>
      <Center>
        <Grid
          alignContent='center'
          alignItems='center'
          templateColumns={{ base: 'repeat(1, 1fr)', md: 'repeat(3, 1fr)' }}
          gap={10}
        >
          <GridItem>
            <Link href='https://rottera.io'>
              <Image src={rotteraLogo} alt='RotteraLogo' />
            </Link>
          </GridItem>
          <GridItem>
            <Link href='https://fitr.mk'>
              <Image src={fitrLogo} alt='FitrLogo' />
            </Link>
          </GridItem>
          <GridItem>
            <Link href='https://aws.amazon.com/'>
              <Image padding={2} marginTop={{ base: 15, md: '2' }} h={65} src={AmazonLogo} alt='AmazonLogo' />
            </Link>
          </GridItem>
        </Grid>
      </Center>
    </Box>
  )
}
