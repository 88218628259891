import { ViewIcon, ViewOffIcon } from '@chakra-ui/icons'
import {
  Alert,
  AlertDescription,
  AlertTitle,
  Button,
  FormControl,
  FormErrorMessage,
  FormLabel,
  Input,
  InputGroup,
  InputRightElement,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay
} from '@chakra-ui/react'
import axios from 'axios'
import React, { useState } from 'react'
import { Form, useField } from 'react-final-form'
import useValidationSchema from 'src/shared/utils/use-validation-schema'
import { useAuth } from 'src/user/useAuth'
import * as yup from 'yup'
const PasswordModal = ({ onOpen, onCloseModal }) => {
  const [showConfirmPassword, setShowConfirmPassword] = useState(false)
  const [showNewPassword, setShowNewPassword] = useState(false)
  const [showOldPassword, setShowOldPassword] = useState(false)
  const [passwordError, setPasswordError] = useState(false)
  const [loading, setLoading] = useState(false)

  const { user } = useAuth()

  const validationSchema = yup.object().shape({
    old_password: yup.string().min(6, 'Password too short!').required('Please enter your password'),
    new_password: yup
      .string()
      .min(6, 'Password too short!')
      .required('Please enter your password')
      .notOneOf([yup.ref('old_password')], 'New password cannot match old password')
      .trim(),
    confirm_password: yup
      .string()
      .min(6, 'Password too short!')
      .required('Please enter your password')
      .oneOf([yup.ref('new_password')], 'Passwords must match')
  })

  const validate = useValidationSchema(validationSchema)

  const onSubmitHandler = (val) => {
    let memberID = user.member_id
    if (val.new_password === val.confirm_password) {
      setLoading(true)
      axios
        .post(process.env.REACT_APP_API_URL + '/members/change_password', {
          member_id: memberID,
          old_password: val.old_password,
          new_password: val.new_password
        })
        .then((res) => {
          setLoading(false)
          if (res.data === 'Password has been successfully changed') {
            return onCloseModal()
          }
          setPasswordError(res.data)
        })
        .catch((err) => {
          console.log(err)
          setPasswordError('')
          setLoading(false)
        })
    } else {
      setPasswordError('Passwords do not match , please make sure there are no spaces')
    }
  }

  return (
    <React.Fragment>
      <Modal
        isOpen={onOpen}
        onClose={() => {
          onCloseModal()
        }}
      >
        <ModalOverlay
          onClick={() => {
            onCloseModal()
          }}
          zIndex={1000}
        />
        <Form
          onSubmit={(values) => {
            onSubmitHandler(values)
          }}
          validate={validate}
          render={({ handleSubmit, form, values }) => (
            <>
              <ModalContent
                as={'form'}
                onSubmit={(e) => {
                  e.preventDefault()
                  handleSubmit(values)
                }}
                marginTop={'95px'}
                w={{ base: '95%', md: '100%' }}
              >
                {passwordError && (
                  <Alert status='error' flexDirection={'column'}>
                    <AlertTitle>Error</AlertTitle>
                    <AlertDescription textAlign={'center'}>{passwordError}</AlertDescription>
                  </Alert>
                )}
                <ModalHeader>Change Password</ModalHeader>
                <ModalBody pb={6}>
                  <FormControl>
                    <FormLabel>Old Password</FormLabel>
                    <InputPasswordControl
                      name='old_password'
                      label='Old Password'
                      placeholder={'Current Password'}
                      show={showOldPassword}
                      showPassword={() => setShowOldPassword(!showOldPassword)}
                    />
                  </FormControl>
                  <FormLabel>New Password</FormLabel>
                  <InputPasswordControl
                    name='new_password'
                    label='New Password'
                    placeholder={'New Password'}
                    show={showNewPassword}
                    showPassword={() => setShowNewPassword(!showNewPassword)}
                  />
                  <FormControl>
                    <FormLabel>Confirm Password</FormLabel>
                    <InputPasswordControl
                      name='confirm_password'
                      label='Confirm Password'
                      placeholder={'Confirm Password'}
                      show={showConfirmPassword}
                      showPassword={() => setShowConfirmPassword(!showConfirmPassword)}
                    />
                  </FormControl>
                </ModalBody>

                <ModalFooter>
                  <Button
                    variant='outline'
                    mr={3}
                    onClick={() => {
                      onCloseModal()
                    }}
                    isLoading={loading}
                  >
                    Cancel
                  </Button>
                  <Button colorScheme='orange' type='submit' isLoading={loading}>
                    Confirm
                  </Button>
                </ModalFooter>
              </ModalContent>
            </>
          )}
        />
      </Modal>
    </React.Fragment>
  )
}

const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputPasswordControl = ({ name, label, placeholder, show, showPassword }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={2}>
      <InputGroup>
        <Input
          {...input}
          isInvalid={meta.error && meta.touched}
          id={name}
          type={show ? 'text' : 'password'}
          placeholder={placeholder}
          _placeholder={{ color: 'rgba(0, 0, 0, 0.36)' }}
          border={'1px'}
          borderRadius={'6px'}
          fontWeight={400}
        />
        <InputRightElement>
          {show ? (
            <ViewOffIcon size={'lg'} color={'rgba(222, 222, 222, 1)'} onClick={showPassword} />
          ) : (
            <ViewIcon size={'lg'} color={'rgba(222, 222, 222, 1)'} onClick={showPassword} />
          )}
        </InputRightElement>
      </InputGroup>
      <Error name={name} />
    </Control>
  )
}

export default PasswordModal
