import { Box, Button, useMediaQuery } from '@chakra-ui/react'

export const BaseButton = ({
  label,
  marginBottom,
  backgroundColor,
  onClick,
  color = 'secondary',
  variant = 'outline',
  icon,
  ...props
}) => {
  const [isMobile] = useMediaQuery('(max-width: 640px)')
  return (
    <Button
      backgroundColor={backgroundColor}
      marginBottom={marginBottom}
      {...props}
      onClick={onClick}
      color={color}
      variant={variant}
    >
      <Box sx={{ marginRight: 2 }}>{icon}</Box>
      {label && <Box display={isMobile ? 'flex' : 'flex'}>{label}</Box>}
    </Button>
  )
}
