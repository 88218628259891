import {
  Box,
  Button,
  Center,
  Heading,
  HStack,
  Image,
  Menu,
  MenuButton,
  MenuList,
  Radio,
  RadioGroup,
  Stack,
  Text,
  VStack
} from '@chakra-ui/react'
import Filter from 'src/assets/filter.svg'
import InPerson from 'src/assets/inPerson.svg'
import Online from 'src/assets/online.svg'
import { BaseButton } from 'src/components/button/BaseButton'
import { EVENT_SIZE, EVENT_TYPE } from 'src/shared/constants'

export const FiltersMobile = ({ eventType, eventSize, onEventTypeHandle, onEventSizeHandle, onResetFilters }) => {
  return (
    <Menu>
      <MenuButton
        as={Button}
        colorScheme='whiteAlpha'
        color='#fff'
        // w={'341px'}
        w={{ base: '341px', md: '650px' }}
        h={'40px'}
        border={'1px'}
        borderRadius={'5px'}
        borderColor={'#E6E6E6'}
        bgColor={'#FFF'}
        display={'flex'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <Center>
          <Image src={Filter} mr={'5px'} />
          <Text color={'#666666'} fontWeight={'medium'} fontSize={'14px'}>
            Filters
          </Text>
        </Center>
      </MenuButton>
      <MenuList
        w={{ base: '341px', md: '650px' }}
        backgroundColor={'#FFFFFF'}
        border={'1px'}
        borderRadius={'5px'}
        borderColor={'#DEDEDE'}
      >
        <Stack direction={'column'} paddingX={'16px'} paddingTop={'10px'} paddingBottom={'10px'} spacing={'20px'}>
          <VStack w={'full'} alignItems='start'>
            <Heading fontSize={'14px'}>Event type</Heading>
            <RadioGroup colorScheme={'blue'} onChange={onEventTypeHandle} value={eventType}>
              <Stack direction='column'>
                <Radio value={EVENT_TYPE.IN_PERSON.URL}>
                  <HStack>
                    <Image src={InPerson} />
                    <Text>In Person</Text>
                  </HStack>
                </Radio>
                <Radio value={EVENT_TYPE.ONLINE.URL}>
                  <HStack>
                    <Image src={Online} />
                    <Text>Online</Text>
                  </HStack>
                </Radio>
                <Radio value={EVENT_TYPE.HYBRID.URL}>
                  <HStack>
                    <Image src={Online} />
                    <Text>Hybrid</Text>
                  </HStack>
                </Radio>
              </Stack>
            </RadioGroup>
          </VStack>
          <VStack w='full' alignItems='start'>
            <Heading fontSize={'14px'}>Event size</Heading>
            <RadioGroup colorScheme={'blue'} onChange={onEventSizeHandle} value={eventSize}>
              <Stack direction='column'>
                <Radio value={EVENT_SIZE.SMALL.URL}>Small</Radio>
                <Radio value={EVENT_SIZE.MEDIUM.URL}>Medium</Radio>
                <Radio value={EVENT_SIZE.LARGE.URL}>Large</Radio>
              </Stack>
            </RadioGroup>
          </VStack>

          <HStack justifyContent={'flex-end'}>
            <Box flex={1}>
              {(eventType || eventSize) && (
                <BaseButton
                  onClick={onResetFilters}
                  label='Reset filters'
                  color='#2D3748'
                  variant='text'
                  fontSize='14px'
                  type='submit'
                  _focus={{
                    boxShadow: 'none'
                  }}
                />
              )}
            </Box>
          </HStack>
        </Stack>
      </MenuList>
    </Menu>
  )
}
