import { Box, Heading, Image, List, ListItem } from '@chakra-ui/react'
import { Link } from 'react-router-dom'
import noCover from 'src/assets/images/nocover.jpg'
import { CalendarIcon, EventIcon, LocationIcon } from 'src/shared/icons/Icons'
import styled from 'styled-components'
import { SvgIcon } from '../icon/SvgIcon'

const ListItemStyled = styled(ListItem)`
  display: flex;
  justify-content: flex-start;
`

export const ConferenceCard = ({ id, image, title, children, location, date, type, icon, ...props }) => {
  return (
    <Box
      maxW='345px'
      w='full'
      boxShadow='xl'
      rounded='xl'
      border='1px solid #E6E6E6'
      p={8}
      overflow='hidden'
      {...props}
    >
      <Link to={`/conference/${id}`}>
        <Box bg={'whiteAlpha.100'} mx={-6} mb={6} mt={-6} pos='relative'>
          {image !== null ? (
            <Image src={image} h='140px' w='100%' objectFit='fill' borderRadius={5} />
          ) : (
            <Image src={noCover} h='140px' w='100%' objectFit='cover' borderRadius={5} />
          )}
        </Box>
        <Heading size={'sm'}>{title}</Heading>
        <Box mt={2}>
          <List spacing={2}>
            <ListItemStyled>
              <SvgIcon icon={LocationIcon} sx={{ marginRight: 2 }} />
              {location}
            </ListItemStyled>
            <ListItemStyled>
              <SvgIcon icon={CalendarIcon} sx={{ marginRight: 2 }} />
              {date}
            </ListItemStyled>
            <ListItemStyled sx={{ display: 'flex', justifyContent: 'flex-start' }}>
              <SvgIcon icon={EventIcon} sx={{ marginRight: 2 }} />
              {type}
            </ListItemStyled>
          </List>
        </Box>
      </Link>
    </Box>
  )
}
