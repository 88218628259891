import { Modal, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from '@chakra-ui/react'
import { GoogleMap, Marker, useLoadScript } from '@react-google-maps/api'
import React from 'react'

export const ConferenceMapModal = ({ isOpen, onClose, conferenceAddress }) => {
  const { isLoaded } = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY
  })

  const lat = parseFloat(conferenceAddress.lat)
  const lng = parseFloat(conferenceAddress.lng)

  return (
    <Modal isCentered isOpen={isOpen} onClose={onClose}>
      <ModalOverlay />
      <ModalContent maxH='65vh' maxW='70%' borderRadius={20}>
        {isLoaded ? (
          <GoogleMap
            mapContainerStyle={{
              height: '65vh',
              minWidth: '70%',
              borderRadius: 15
            }}
            defaultZoom={11}
            center={{ lat: lat, lng: lng }}
            zoom={11}
            options={{ fullscreenControl: false, mapTypeControl: false, streetViewControl: false }}
          >
            <Marker position={{ lat: lat, lng: lng }} />
          </GoogleMap>
        ) : (
          <ModalHeader>
            <h1>Loading ..</h1>
          </ModalHeader>
        )}
        <ModalCloseButton sx={{ backgroundColor: '#F26C4F', color: 'white' }} />
      </ModalContent>
    </Modal>
  )
}
