import { Box, Container, Divider, Flex, Grid, GridItem, Skeleton, SkeletonText, VStack } from '@chakra-ui/react'
import React from 'react'

export const ConferenceDetailPageSkeleton = () => {
  return (
    <>
      <Box w={{ md: 'full', sm: 'full' }} height={{ md: '600px', sm: '20px' }} h='35vh' bgColor={'#dfe5ec'} />
      <Container
        paddingTop={{ sm: '10', md: '20' }}
        maxW={{ sm: 'container.sm', md: '75%' }}
        borderRadius={{ base: 'none', md: 'md' }}
        marginTop='-400px'
        background='#fff'
      >
        <Grid
          templateRows={{ sm: 'repeat(3, 1fr)', md: 'repeat(1, 1fr)' }}
          templateColumns={{ sm: 'repeat(1, 1fr)', md: 'repeat(1, 1fr)' }}
          padding={2}
        >
          <GridItem>
            <Grid
              templateRows={{ sm: 'repeat(3, 1fr)', md: 'repeat(2, 1fr)' }}
              templateColumns={{ sm: 'repeat(3, 1fr)', md: 'repeat(5, 1fr)' }}
              gap={4}
            >
              <GridItem rowSpan={2} colSpan={1}>
                <Skeleton h='200px' w='200px' rounded='md' display={{ base: 'none', md: 'block' }} />
              </GridItem>
              <GridItem rowSpan={2} colSpan={4}>
                <VStack spacing={2} align='stretch'>
                  <Skeleton h={'35px'} w={'250px'} mb='25px' />
                  <SkeletonText noOfLines={3} spacing={3} w='100%' h='40px' />
                  <Flex minWidth='max-content' alignItems='center' gap='2' pt={'12px'}>
                    <Skeleton h={'20px'} w={'135px'} rounded='md' />
                    <Skeleton h={'20px'} w={'135px'} rounded='md' />
                    <Skeleton h={'20px'} w={'135px'} rounded='md' />
                    <Skeleton h={'20px'} w={'135px'} rounded='md' />
                  </Flex>
                </VStack>
              </GridItem>
            </Grid>
            <Divider variant='dashed' mt={10} />
          </GridItem>

          <GridItem mt={5}>
            <Skeleton h={'25px'} w={'150px'} mb='25px' />
            <Grid
              templateRows={{ sm: 'repeat(3, 1fr)', md: 'repeat(1, 1fr)' }}
              templateColumns={{ sm: 'repeat(3, 1fr)', md: 'repeat(5, 1fr)' }}
              gap={4}
            >
              <GridItem rowSpan={{ sm: '1', md: '1' }} colSpan={{ sm: '1', md: '1' }}>
                <Skeleton h='250px' w='250px' rounded='md' />
              </GridItem>
              <GridItem rowSpan={2} colSpan={4} mt={{ sm: '2', md: '10' }}>
                <VStack spacing={2} align='stretch'>
                  <Skeleton h={'25px'} w={'200px'} mb='25px' />
                  <SkeletonText noOfLines={2} spacing={3} w='250px' />
                </VStack>
              </GridItem>
            </Grid>
            <Divider variant='dashed' mt={10} />
          </GridItem>
        </Grid>
      </Container>
    </>
  )
}
