import { Box, Spinner, Stack, Text } from '@chakra-ui/react'

export const SpinnerCard = ({ text }) => {
  return (
    <Box>
      <Stack direction={'column'} justifyContent={'center'} alignItems={'center'}>
        {text !== 'registered' && text !== 'errorVerifying' && (
          <Spinner
            thickness='5px'
            speed='0.65s'
            emptyColor='gray.200'
            color='rgba(242, 108, 79, 1)'
            w={'80px'}
            h={'80px'}
          />
        )}

        <Text align={'center'} fontSize={'22px'} fontWeight={'400'}>
          {text === 'logIn' && 'We’re taking you to to your account. Hang tight!'}
          {text === 'registering' && 'Account was created successfully!'}
          {text === 'registered' && 'Please check your email and verify your account!'}
          {text === 'verified' && 'Account verified successfully'}
          {text === 'errorVerifying' && 'Error verifying account'}
          {text === 'verifying' && 'Verifying...'}
        </Text>
      </Stack>
    </Box>
  )
}
