import { HttpService } from '..'

export class ConferencesService extends HttpService {
  constructor() {
    super()
    this.path = '/conferences'
  }

  getConferences() {
    return this.get(`${this.path}/active`)
  }

  getConferenceBySlug(slug) {
    const params = {
      slug
    }
    return this.get(`${this.path}/active`, { params })
  }

  searchConferences({ q, type, size, t }) {
    const params = {
      q,
      type,
      size,
      t
    }

    return this.get(`${this.path}/search`, { params })
  }

  switchFavorite(member_id, slug) {
    const body = {
      member_id,
      slug
    }
    return this.post(`/switch_favorite`, body)
  }
}
