import jwtDecode from 'jwt-decode'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const authContext = createContext()
export const SESSION = '__gcsession'

export function ProvideAuth({ children }) {
  const auth = useProvideAuth()
  return <authContext.Provider value={auth}>{children}</authContext.Provider>
}

export const useAuth = () => {
  return useContext(authContext)
}

function useProvideAuth() {
  // TODO: To be updated and improved when user can login
  const [user, setUser] = useState(null)
  const [loading, setLoading] = useState(true)

  const navigate = useNavigate()

  const login = async (access_token) => {
    let res = jwtDecode(access_token)
    setUser(res)
    localStorage.setItem(SESSION, access_token)
    navigate('/')
  }

  const logout = () => {
    setUser(null)
    localStorage.removeItem(SESSION)
    navigate('/')
    return null
  }

  useEffect(() => {
    if (localStorage.getItem(SESSION)) {
      const token = localStorage.getItem(SESSION)
      let res = jwtDecode(token)
      setUser(res)
      setLoading(false)
    } else {
      setUser(null)
      setLoading(false)
    }
  }, [])

  return {
    loading,
    user,
    login,
    logout
  }
}
