import { Link } from '@chakra-ui/react'
import CookieConsent from 'react-cookie-consent'

const Cookies = () => {
  return (
    <CookieConsent
      cookieName='test-cookie'
      cookieValue='test-value'
      enableDeclineButton
      buttonText='Accept'
      declineButtonText='Decline'
      style={{ backgroundColor: '#030B30', alignItems: 'center' }}
      buttonStyle={{ backgroundColor: '#DD6B20', color: '#fff', borderRadius: '5px' }}
      declineButtonStyle={{ borderRadius: '5px' }}
      expires={100} //in days
    >
      This site uses cookies to enhance user experience. Check out our{' '}
      <Link color='blue.300' href='/privacy'>
        Privacy Policy
      </Link>{' '}
      for more information
    </CookieConsent>
  )
}

export default Cookies
