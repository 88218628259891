import { Box, Heading, Text } from '@chakra-ui/react'
import React from 'react'
import { ScrollDetector } from '../scroll-detector/ScrollDetector'
import './styles.css'

export const BenchmarkFeature = ({ featureNumber, featureTitle }) => {
  const Benchmark = (
    <div className='animate glow delay'>
      <Box fontWeight='semibold' lineHeight='tight'>
        <Heading as='h1' size='4xl' bgGradient='linear( #000000, #0B97FD)' textAlign='center' bgClip='text'>
          {featureNumber}
        </Heading>
        <Text color='#999999' fontWeight='thin' textAlign='center' marginTop='10px'>
          {featureTitle}
        </Text>
      </Box>
    </div>
  )

  return <ScrollDetector Component={Benchmark} />
}
