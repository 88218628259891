export const EVENT_TYPE = {
  IN_PERSON: {
    URL: 'in-person',
    VALUE: 'IN_PERSON'
  },
  ONLINE: {
    URL: 'online',
    VALUE: 'ONLINE'
  },
  HYBRID: {
    URL: 'hybrid',
    VALUE: 'HYBRID'
  }
}

export const EVENT_SIZE = {
  SMALL: {
    URL: 'small',
    VALUE: '0'
  },
  MEDIUM: {
    URL: 'medium',
    VALUE: '1'
  },
  LARGE: {
    URL: 'large',
    VALUE: '2'
  }
}

export const TIMELINE = {
  UPCOMING: 'upcoming',
  PAST: 'past'
}

export const resolveParamValue = (param, urlValue) => {
  if (!urlValue) {
    return null
  }
  return Object.values(param === 'type' ? EVENT_TYPE : EVENT_SIZE).find((type) => type.URL === urlValue).VALUE
}
