import { Box, Center, SimpleGrid } from '@chakra-ui/react'
import axios from 'axios'
import { useEffect, useState } from 'react'
import { BenchmarkFeature } from './BenchmarkFeature'

export const Benchmarks = () => {
  const [conf, setConf] = useState(0)
  const [countries, setCountries] = useState(0)
  const [speakers, setSpeakers] = useState(0)
  const [loading, setLoading] = useState(true)

  useEffect(() => {
    axios
      .get(process.env.REACT_APP_API_URL + '/conference_stats')
      .then((res) => {
        setConf(res.data.conferences)
        setCountries(res.data.countries)
        setSpeakers(res.data.speakers)
        setLoading(false)
      })
      .catch((err) => {
        console.log(err)
      })
  }, [])

  return (
    <Box bg='#fff' paddingY={{ base: '40px', md: '70px' }} id='benchmark-items'>
      <Center>
        <SimpleGrid columns={{ base: 1, md: 3 }} spacing={{ base: '10', md: '40', lg: '80' }}>
          {!loading && (
            <>
              <BenchmarkFeature index={0} featureNumber={conf} featureTitle='Conferences' />
              <BenchmarkFeature index={1} featureNumber={countries} featureTitle='Countries' />
              <BenchmarkFeature index={2} featureNumber={speakers} featureTitle='Speakers' />
            </>
          )}
        </SimpleGrid>
      </Center>
    </Box>
  )
}
