import { SearchIcon } from '@chakra-ui/icons'
import {
  Box,
  Flex,
  Heading,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  VStack,
  useBreakpointValue,
  useMediaQuery
} from '@chakra-ui/react'
import { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import BannerPhoto from 'src/assets/images/GC-banner1.png'
import { BaseButton } from '../button/BaseButton'

export const Banner = () => {
  const [search, setSearch] = useState('')
  const navigate = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 640px)')
  const [isMedium] = useMediaQuery('(max-width: 820px)')

  const onSearchHandler = () => {
    if (search.length > 0) {
      navigate(`/search?q=${search}`)
    }
  }

  return (
    <Flex flexDirection='column' alignItems='center' bgColor='#FAFAFA'>
      <Box position='absolute'>
        <Image
          src={BannerPhoto}
          alt='BannerPhoto'
          h='auto'
          maxW='100%'
          objectFit='cover'
          pt='14'
          w={{ base: '360px', md: '500px', lg: '540px' }}
        />
      </Box>
      <Flex
        m={{ base: '10px auto', md: 'auto' }}
        w={{ base: '100%', md: '90%' }}
        maxW='1105px'
        h='400px'
        borderRadius={{ base: 'none', md: '75px' }}
        overflow='hidden'
      >
        <VStack justifyContent='center' w='full' px={useBreakpointValue({ base: 4, md: 8 })} spacing={4} mt='25px'>
          <Stack>
            <Heading size='lg' fontWeight={400} fontFamily='Helvetica' color='#020B3080' m='20px'>
              Explore conferences, effortlessly.
            </Heading>
          </Stack>
          <form onSubmit={onSearchHandler} noValidate>
            <InputGroup
              boxShadow='0px 5px 20px rgba(0, 0, 0, 0.20)'
              borderRadius='10px'
              overflow='hidden'
              size='lg'
              w={{ base: '350px', md: '400px', lg: '700px' }}
            >
              <Input
                variant={'unstyled'}
                _placeholder={{ fontSize: isMobile ? '20px' : 'auto' }}
                placeholder='Conference name'
                focusBorderColor='none'
                backgroundColor={'rgba(255, 255, 255, 1)'}
                border='none'
                pl={5}
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
              <InputRightAddon border='none' backgroundColor='#fff' bgColor='white'>
                <BaseButton
                  icon={<SearchIcon color={isMedium ? '#F26C4F' : 'white'} />}
                  type='submit'
                  backgroundColor={isMedium ? 'white' : '#F26C4F'}
                  label={!isMobile && !isMedium ? 'Search' : ''}
                  marginRight='-10px'
                  variant='text'
                  _focus={{
                    boxShadow: 'none'
                  }}
                  color='white'
                  sx={{ borderRadius: 10 }}
                />
              </InputRightAddon>
            </InputGroup>
          </form>
        </VStack>
      </Flex>
    </Flex>
  )
}
