import { ChakraProvider, extendTheme } from '@chakra-ui/react'
import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import Cookies from './components/cookie/AcceptCookiesContainer'
import { AllRoutes } from './routes/AllRoutes'
import { ProvideAuth } from './user/useAuth'

export const App = () => {
  const theme = extendTheme({
    colors: {
      gc: {
        100: '#fff',
        200: '#F58972',
        300: '#F26C4F'
      }
    }
  })
  return (
    <ChakraProvider theme={theme}>
      <BrowserRouter>
        <ProvideAuth>
          <AllRoutes />
          <Cookies />
        </ProvideAuth>
      </BrowserRouter>
    </ChakraProvider>
  )
}
