import { CheckCircleIcon } from '@chakra-ui/icons'
import { Button, Flex, Heading, HStack, Image, useMediaQuery, VStack } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import LogoBlack from 'src/assets/logo-black.svg'

const ConfirmationScreen = () => {
  const nav = useNavigate()
  const [isMobile] = useMediaQuery('(max-width: 640px)')

  return (
    <Flex justify='center' align='center' width='100%' height='100vh'>
      <VStack width='75%' py={8} px={5}>
        {isMobile && (
          <Image src={LogoBlack} height={'25px'} marginBottom={'100px'} marginTop={-20} onClick={() => nav('/')} />
        )}
        <HStack w='100%' p={5} bg='green.100'>
          <CheckCircleIcon color={'green'} />
          <Heading as='h5' size='sm'>
            Password has been reset successfully!
          </Heading>
        </HStack>
        <Button
          w='100%'
          onClick={() => {
            nav('/login')
          }}
          colorScheme='orange'
          my={3}
        >
          To Account
        </Button>
      </VStack>
    </Flex>
  )
}

export default ConfirmationScreen
