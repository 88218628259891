import { StarIcon } from '@chakra-ui/icons'
import { Image, Tab, TabList, TabPanel, TabPanels, Tabs } from '@chakra-ui/react'
import myTickets from '../../../assets/icons/my-tickets.svg'
import { EventsFavorites } from './FavoriteEvents'
export const ProfileEvents = () => {
  return (
    <Tabs variant='unstyled'>
      <TabList>
        <Tab _selected={{ color: 'rgba(8, 31, 134, 1)', borderBottom: '3px solid #081F86', fontWeight: '500' }}>
          <StarIcon mr={2} />
          Favorites
        </Tab>
        <Tab
          mx={3}
          _selected={{ color: 'rgba(8, 31, 134, 1)', borderBottom: '3px solid #081F86' }}
          backgroundColor={'gray.100'}
          isDisabled={true}
        >
          <Image src={myTickets} height={18} width={18} alt='' mr={2} />
          My Tickets
        </Tab>
      </TabList>
      <TabPanels>
        <TabPanel>
          <EventsFavorites />
        </TabPanel>
        <TabPanel>My Tickets</TabPanel>
      </TabPanels>
    </Tabs>
  )
}
