import { Box, Stack, Text } from '@chakra-ui/react'

export const NoResult = () => {
  return (
    <>
      <Box w={'full'} margin={'15px'}>
        <Stack direction={'row'} spacing={'15px'}>
          <Text fontSize={'14px'} color={'rgba(51, 51, 51, 1)'} fontWeight={'400px'}>
            Unfortunately there are no results for the searched title.
          </Text>
        </Stack>
      </Box>
    </>
  )
}
