import { CalendarIcon } from '@chakra-ui/icons'
import { Box, Divider, Flex, Image, Tab, TabList, TabPanel, TabPanels, Tabs, useMediaQuery } from '@chakra-ui/react'
import React, { useEffect, useMemo, useState } from 'react'
import { Navigate } from 'react-router-dom'
import { MemberService } from 'src/service/members/MemberService'
import { useAuth } from 'src/user/useAuth'
import DefaultProfilePicture from '../../assets/images/profile-default-banner.png'
import shieldIcon from '../../assets/shield.svg'
import personalInformationIcon from '../../assets/user.svg'
import { Footer } from '../footer/Footer'
import { Header } from '../header/Header'
import { PersonalInformationPanel } from './panels/PersonalInformationPanel'
import { ProfileEvents } from './panels/ProfileEvents'
import UserHeader from './UserHeader'
export const UserProfilePage = () => {
  const [name, setName] = useState()
  const [lastName, setLastName] = useState()
  const [email, setEmail] = useState()
  const [profilePicture, setProfilePicture] = useState(null)
  const [coverPicture, setCoverPicture] = useState(null)
  const [initialFetch, setInitialFetch] = useState(true)
  const [isMobile] = useMediaQuery('(max-width: 640px)')
  const { user, loading } = useAuth()
  const memberService = useMemo(() => new MemberService(), [])

  useEffect(() => {
    const initData = async () => {
      const { data, error } = await memberService.current()
      if (error) {
        console.log('Error', error)
        return
      }
      setName(data?.first_name)
      setLastName(data?.last_name)
      setEmail(data?.email)
      setProfilePicture(data?.profile)
      setCoverPicture(data?.cover)
      setInitialFetch(false)
    }

    initData()
  }, [user, memberService])

  if (!loading && !user) {
    return <Navigate replace to='/login' />
  }

  const refetchDataHandler = async () => {
    const { data, error } = await memberService.current()
    if (error) {
      console.log('Error', error)
      return
    }
    setName(data?.first_name)
    setLastName(data?.last_name)
    setEmail(data?.email)
    setProfilePicture(data?.profile)
    setCoverPicture(data?.cover)
  }

  return (
    <>
      <Header />
      <Flex
        zIndex={10}
        w={'full'}
        h={{ base: '130px' }}
        backgroundImage={coverPicture ? coverPicture.url : DefaultProfilePicture}
        backgroundSize={'cover'}
        backgroundPosition={'center center'}
        columns={{ base: 1, md: 3 }}
      />
      <Box
        display={'flex'}
        justifyContent='center'
        alignItems='center'
        w={{ md: '95%', lg: '90%', xl: '80%', base: '100%' }}
        my={{ base: 10, md: 7 }}
        transition={'all ease-out 100ms'}
        mx='auto'
      >
        <Flex
          transform={{ base: 'translateY(-80px)' }}
          direction={{ base: 'column' }}
          borderRadius={5}
          width='100%'
          h={'fit-content'}
          background='#fff'
          overflow={'hidden'}
        >
          <UserHeader name={name} lastName={lastName} profile={profilePicture} initialFetch={initialFetch} />
          <Flex w='100%' h={'fit-content'}>
            <Tabs
              variant='soft-rounded'
              w='100%'
              display='flex'
              flexDirection={isMobile ? 'column' : 'row'}
              h='fit-content'
              my={3}
              justifyContent={isMobile && 'center'}
            >
              <TabList
                w={!isMobile ? '35%' : '100%'}
                display='flex'
                flexDirection={!isMobile ? 'column' : 'row'}
                h='100%'
                px={3}
              >
                <Tab borderRadius={'10px'} justifyContent='start'>
                  <Image
                    mr={2}
                    w={{ base: 0, md: 4 }}
                    h={{ base: 0, md: 4 }}
                    src={personalInformationIcon}
                    alt='Personal Information'
                  />
                  Personal Information
                </Tab>
                <Tab borderRadius={'10px'} justifyContent='start'>
                  <CalendarIcon display={{ base: 'none', md: 'block' }} mr={2} />
                  Events
                </Tab>
                <Tab borderRadius={'10px'} justifyContent='start'>
                  <Image mr={2} w={{ base: 0, md: 4 }} h={{ base: 0, md: 4 }} src={shieldIcon} alt='Security' />
                  Security
                </Tab>
              </TabList>
              <Divider orientation='vertical' h='auto' borderLeftWidth={'3px'} borderColor='#c2c2c2' />
              <TabPanels w={'75%'} h={'100%'}>
                <TabPanel>
                  <PersonalInformationPanel
                    headerPicture={coverPicture}
                    lastName={lastName}
                    name={name}
                    email={email}
                    memberId={user?.member_id}
                    profile={profilePicture}
                    refetchData={refetchDataHandler}
                    initialFetch={initialFetch}
                  />
                </TabPanel>
                <TabPanel>
                  <ProfileEvents />
                </TabPanel>
                <TabPanel>
                  <p>Coming Soon...</p>
                </TabPanel>
              </TabPanels>
            </Tabs>
          </Flex>
        </Flex>
      </Box>
      <Footer />
    </>
  )
}
