import { ArrowBackIcon, ArrowForwardIcon, AtSignIcon, CalendarIcon, ViewIcon } from '@chakra-ui/icons'
import { Box, Button, Heading, Image, Stack, Text } from '@chakra-ui/react'
import { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

export const SearchCard = ({ slug, image, title, description, location, date, type, isFavorite }) => {
  const [showText, setShowText] = useState(false)
  const [isOverflow, setIsOverflow] = useState(false)
  const textRef = useRef()

  useEffect(() => {
    const element = textRef.current

    setIsOverflow(
      element ? element.offsetWidth < element.scrollWidth || element.offsetHeight < element.scrollHeight : false
    )
  }, [])

  return (
    <Box w={{ md: '650px', lg: '100%' }} margin={'15px'} h={'fit-content'}>
      <Stack direction={{ base: 'column', md: 'row' }} spacing={'15px'}>
        <Image src={image} maxW={'300px'} minW={'300px'} h={'180px'} borderRadius={'5px'} objectFit='unset' />
        <Box display={'flex'}>
          <Stack display={'flex'} justifyContent={'space-evenly'}>
            <Stack>
              <Heading fontSize={'22px'} fontWeight={'semibold'}>
                {title}
              </Heading>
            </Stack>
            <Stack>
              <Text
                fontSize={'14px'}
                color={'rgba(51, 51, 51, 1)'}
                fontWeight={'400px'}
                noOfLines={showText ? undefined : 2}
                ref={textRef}
              >
                {description}
              </Text>
              {isOverflow && (
                <Button
                  h='fit-content'
                  rightIcon={!showText ? <ArrowForwardIcon /> : <ArrowBackIcon />}
                  variant='link'
                  color='#222'
                  w='fit-content'
                  onClick={() => {
                    setShowText(!showText)
                  }}
                >
                  {showText ? 'Read Less' : 'Read More'}
                </Button>
              )}
            </Stack>
            <Stack>
              <Stack direction={{ base: 'column', md: 'row' }} spacing={'auto'}>
                <Stack display={'flex'} alignItems={'center'} direction={'row'}>
                  <AtSignIcon />
                  <Box paddingLeft={'1px'} mr={3}>{location}</Box>
                </Stack>
                <Stack display={'flex'} mr={3} alignItems={'center'} direction={'row'}>
                  <CalendarIcon />
                  <Box paddingLeft={'1px'}>{date}</Box>
                </Stack>
                <Stack display={'flex'} alignItems={'center'} direction={'row'}>
                  <ViewIcon />
                  <Box paddingLeft={'1px'} mr={3}>{type}</Box>
                </Stack>
              </Stack>
            </Stack>

            <Stack direction={'row'} spacing={'15px'}>
              <Link to={`/conference/${slug}`}>
                <Button color={'#FFFFFF'} backgroundColor={'#030B30'} variant={'solid'}>
                  View Details
                </Button>
              </Link>
            </Stack>
          </Stack>
        </Box>
      </Stack>
    </Box>
  )
}
