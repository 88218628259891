import calendarSvg from 'src/assets/icons/calendar.svg'
import conferenceDetailsCalendar from 'src/assets/icons/conference-details-calendar.svg'
import conferenceDetailsEventType from 'src/assets/icons/conference-details-event-type.svg'
import conferenceDetailsLocation from 'src/assets/icons/conference-details-location.svg'
import eventType from 'src/assets/icons/event-type.svg'
import locationSvg from 'src/assets/icons/location.svg'
import website from 'src/assets/icons/website.svg'

export const LocationIcon = locationSvg
export const CalendarIcon = calendarSvg
export const EventIcon = eventType
export const WebsiteIcon = website
export const DetailsCalendarIcon = conferenceDetailsCalendar
export const DetailsEventIcon = conferenceDetailsEventType
export const DetailsLocationIcon = conferenceDetailsLocation
