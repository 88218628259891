import { Box, Skeleton, SkeletonText } from '@chakra-ui/react'

export const ConferenceSkeletonCard = () => {
  return (
    <Box minW='345px' w='full' boxShadow='xl' rounded='xl' border='1px solid #E6E6E6' p={8} overflow='hidden'>
      <Box bg='whiteAlpha.100' mx={-6} mb={6} mt={-6} pos='relative'>
        <Skeleton h='160px' w='full' />
      </Box>
      <Skeleton height='20px' width='60%' mt={2} />
      <Skeleton height='15px' width='50%' mt={2} />
      <SkeletonText noOfLines={2} spacing={4} mt={5} />
    </Box>
  )
}
