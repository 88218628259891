import { Box, Divider, Flex, Heading, HStack, Image, Link, Text } from '@chakra-ui/react'
import facebookLogo from 'src/assets/facebookLogo.svg'
import instagramLogo from 'src/assets/instagramLogo.svg'
import linkedInLogo from 'src/assets/linkedInLogo.svg'
const CareersSubmit = () => {
  return (
    <Flex bg={'#030B30'} p={{ base: 3, md: 7 }} w='100%' justify='center' align='center' direction={'column'}>
      <Box py={5} textAlign={'center'}>
        <Heading as='h4' size='md' color='#F26C4F'>
          Thank you for your interest!
        </Heading>
        <Text py={5} color='#fff' fontSize='md'>
          We value good talent and will make sure you’re contacted as soon as we have an open position that fits your
          profile.
        </Text>
      </Box>
      <Divider py={{ base: 3, md: 6 }} />
      <HStack py={6} w='100%' justify='space-evenly'>
        <Link href='https://facebook.com/'>
          <Image w={10} h={10} src={facebookLogo} alt='Facebook Logo' />
        </Link>
        <Link href='https://instagram.com/'>
          <Image w={10} h={10} src={instagramLogo} alt='Instagram Logo' />
        </Link>
        <Link href='https://linkedin.com'>
          <Image w={10} h={10} src={linkedInLogo} alt='Linkedin Logo' />
        </Link>
      </HStack>
      <Text color='#fff' Text fontSize='sm'>
        Follow GoConf on Social Media and stay up to date.
      </Text>
    </Flex>
  )
}

export default CareersSubmit
