import { CareersPage } from 'src/components/careers/CareersPage'
import ComingSoon from 'src/components/coming-soon'
import { UserProfilePage } from 'src/components/profile/ProfilePage'
import { VerifyUser } from 'src/components/verify-user/VerifyUser'
import { AboutUsPage } from 'src/screens/about-us/AboutUsPage'
import { ConferenceDetailPage } from 'src/screens/conference/detail/ConferenceDetailPage'
import { LoginForgotPassword } from 'src/screens/forgot-password/ForgotPassword'
import { ResetPassword } from 'src/screens/forgot-password/ResetPassword'
import { HomePage } from 'src/screens/home/HomePage'
import { LoginPage } from 'src/screens/login/LoginPage'
import { NotFoundPage } from 'src/screens/not-found/NotFoundPage'
import { PrivacyPolicy } from 'src/screens/privacy-policy/PrivacyPolicy'
import { PublishEventPage } from 'src/screens/publish-event/PublishEventPage'
import { RegisterPage } from 'src/screens/register/RegisterPage'
import { SearchPage } from 'src/screens/search-page/SearchPage'

export const ROUTES = {
  HomeIndexPage: {
    path: '/',
    component: HomePage,
    label: 'Home'
  },
  ConferenceDetailPage: {
    path: '/conference/:conferenceSlug',
    component: ConferenceDetailPage,
    label: 'Conference details'
  },
  UserProfilePage: {
    path: '/user',
    component: UserProfilePage,
    label: 'Profile Page'
  },
  CareersPage: {
    path: '/careers',
    component: CareersPage,
    label: 'Careers '
  },
  LoginPage: {
    path: '/login',
    component: LoginPage,
    label: 'Login'
  },
  RegisterPage: {
    path: '/register',
    component: RegisterPage,
    label: 'Register'
  },
  SearchPage: {
    path: '/search',
    component: SearchPage,
    label: 'Search'
  },
  ExplorePage: {
    path: '/explore',
    component: SearchPage,
    label: 'Explore'
  },
  ForgotPassword: {
    path: '/login/forgot-password',
    component: LoginForgotPassword,
    label: 'Forgot Password'
  },
  ResetPassword: {
    path: '/login/reset-password/:token',
    component: ResetPassword,
    label: 'Reset Password'
  },
  PrivacyPage: {
    path: '/privacy',
    component: PrivacyPolicy,
    label: 'Privacy'
  },
  PublishEventPage: {
    path: '/publish-event',
    component: PublishEventPage,
    label: 'Publish an event'
  },

  AboutUsPage: {
    path: '/about-us',
    component: AboutUsPage,
    label: 'About us'
  },
  TermsOfUse: {
    path: '/terms-of-use',
    component: ComingSoon,
    label: 'Terms of use'
  },
  CostumerSupport: {
    path: '/customer-service',
    component: ComingSoon,
    label: 'Customer Support'
  },
  VerifyUser: {
    path: '/verify/:token',
    component: VerifyUser,
    label: 'Verify User'
  },
  NotFoundPage: {
    path: '*',
    component: NotFoundPage,
    label: 'Not Found'
  }
}

export const isPathAllowedForRole = (path, roles) => {
  const routeKeyMatch = Object.keys(ROUTES).find((key) => ROUTES[key].path === path)

  if (!routeKeyMatch) {
    return false
  }

  const route = ROUTES[routeKeyMatch]
  if (!route.roles) {
    return true
  }

  return route.roles.some((role) => roles.includes(role))
}

export const getPublicPaths = () => {
  return Object.values(ROUTES)
    .filter((r) => r.roles === undefined)
    .map((i) => i.path)
}
