import {
  Box,
  Button,
  FormControl,
  FormErrorMessage,
  Heading,
  Image,
  Input,
  Link,
  Text,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import axios from 'axios'
import { useState } from 'react'
import { Form, useField } from 'react-final-form'
import { useNavigate } from 'react-router-dom'
import LogoBlack from 'src/assets/logo-black.svg'
import { LoginLayout } from 'src/components/login/LoginLayout'
import useValidationSchema from 'src/shared/utils/use-validation-schema'
import * as yup from 'yup'

export const LoginForgotPassword = () => {
  const nav = useNavigate()
  const [loading, setLoading] = useState(false)
  const [isMobile] = useMediaQuery('(max-width: 640px)')
  const [checkEmail, setCheckEmail] = useState(false)

  const validationSchema = yup.object().shape({
    email: yup.string().email().label('Email').required('Please enter your email')
  })

  const validate = useValidationSchema(validationSchema)

  const onSubmit = (values) => {
    setLoading(true)
    axios
      .post(process.env.REACT_APP_API_URL + '/members/forget_password', {
        email: values.email
      })
      .then((res) => {
        setCheckEmail(true)
      })
      .catch((err) => {
        setLoading(false)
        alert(err.response.data)
      })
  }

  return (
    <LoginLayout>
      <VStack
        display='flex'
        alignItems='start'
        w={isMobile ? undefined : { base: '230px', md: '350px', lg: '430px' }}
        paddingBottom='10px'
        paddingTop='10px'
        padding={isMobile ? '30px' : 'none'}
      >
        {isMobile && <Image src={LogoBlack} height={'25px'} marginBottom='20px' onClick={() => nav('/')} />}
        <Heading color={'rgba(13, 51, 84, 1)'} fontSize='30px' textAlign='start'>
          {!checkEmail ? 'Reset your password' : 'Email Sent'}
        </Heading>
        <Text fontSize='sm' textAlign='start' color='GrayText'>
          {!checkEmail
            ? 'Please enter the email address associated with your account and we’ll send you a link to reset the password.'
            : 'Please check your email for further instructions.'}
        </Text>
        {!checkEmail && (
          <>
            <Form
              onSubmit={onSubmit}
              validate={validate}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <Box as='form' w={'100%'} onSubmit={handleSubmit}>
                  <InputControl name='email' label='Email' placeholder={'example@goconf.io'} />
                  <Button
                    type='submit'
                    isLoading={loading}
                    w='100%'
                    bgColor={'rgba(242, 108, 79, 1)'}
                    color={'rgba(255, 255, 255, 1)'}
                  >
                    Continue
                  </Button>
                </Box>
              )}
            />
            <Text fontSize='md' color='GrayText'>
              Don’t have an account yet?&nbsp;
              <Link onClick={() => nav('/register')} fontWeight='600' color='blue.400'>
                Sign Up
              </Link>
              &nbsp;now.
            </Text>
          </>
        )}
      </VStack>
    </LoginLayout>
  )
}

const Control = ({ name, ...rest }) => {
  const {
    meta: { error, touched }
  } = useField(name, { subscription: { touched: true, error: true } })
  return <FormControl {...rest} isInvalid={error && touched} />
}

const Error = ({ name }) => {
  const {
    meta: { error }
  } = useField(name, { subscription: { error: true } })
  return <FormErrorMessage>{error}</FormErrorMessage>
}

const InputControl = ({ name, label, placeholder }) => {
  const { input, meta } = useField(name)
  return (
    <Control name={name} my={4} pb={'10px'}>
      <Input
        {...input}
        isInvalid={meta.error && meta.touched}
        id={name}
        placeholder={placeholder}
        _placeholder={{ color: 'rgba(0, 0, 0, 0.36)' }}
        border={'1px'}
        borderRadius={'6px'}
        fontWeight={400}
      />
      <Error name={name} />
    </Control>
  )
}
