import { Box, Flex, Heading, Image, Link, List, ListItem, Stack, Text } from '@chakra-ui/react'
import React from 'react'
import contactInfoIcon from 'src/assets/contact-info.svg'
import IdentifiersIcon from 'src/assets/fingerprint.svg'
import privacyLocationIcon from 'src/assets/privacy-location.svg'
import searchHistoryIcon from 'src/assets/search-history.svg'
import UsageDataIcon from 'src/assets/usage-data.svg'
import userContentIcon from 'src/assets/user-content.svg'
import { Footer } from 'src/components/footer/Footer'
import { Header } from 'src/components/header/Header'
export const PrivacyPolicy = () => {
  return (
    <>
      <Header />
      <Flex justify='center' align='center' minH={'80vh'} w={'75%'}>
        <Stack w={'85%'} my={10}>
          <Heading as='h2' size='2xl' fontWeight={500} color='#F26C4F' mb={10} mt={3}>
            Privacy Policy of Goconf
          </Heading>

          <Box py={4}>
            <Heading fontWeight={500} as='h4' size='lg' mb={3}>
              1.Privacy Policy
            </Heading>
            <Text fontSize='md'>
              This Privacy Policy describes our policies and procedures on the collection, use and disclosure of your
              information when you use the service and tells you about your privacy rights and how the law protects you.
              We use your personal data to provide and improve the service. By using the service, you agree to the
              collection and use of information in accordance with this Privacy Policy. This Privacy Policy has been
              created with the help of the Privacy Policy Generator
            </Text>
          </Box>
          <Box Box py={4}>
            <Heading fontWeight={500} as='h4' size='lg' mb={3}>
              2. Interpretation & Definitions
            </Heading>
            <Text fontSize='md'>
              The words of which the initial letter is capitalized have meanings defined under the following conditions.
              The following definitions shall have the same meaning regardless of whether they appear in singular or in
              plural. Definitions: Account means a unique account created for you to access our service or parts of our
              service. Company (referred to as either "the Company", "We", "Us" or "Our" in this Agreement) refers to
              GoConf. Cookies are small files that are placed on your computer, mobile device or any other device by a
              website, containing the details of your browsing history on that website among its many uses. Country
              refers to North Macedonia. Device means any device that can access the service such as a computer, a
              cellphone or a digital tablet. Personal Data is any information that relates to an identified or
              identifiable individual. Service refers to the Website and App. Service Provider means any natural or
              legal person who processes the data on behalf of the Company. It refers to third-party companies or
              individuals employed by the Company to facilitate the service, to provide the service on behalf of the
              Company, to perform services related to the service or to assist the Company in analyzing how the service
              is used. Third-party social media service refers to any website or any social network website through
              which a user can log in or create an account to use the service. Usage Data refers to data collected
              automatically, either generated by the use of the service or from the service infrastructure itself (for
              example, the page from which you were redirected). Website refers to GoConf, accessible from
              https://goconf.io. You means the individual accessing or using the service, or the company, or other legal
              entity on behalf of which such individual is accessing or using the service, as applicable.
            </Text>
          </Box>
          <Box py={4}>
            <Heading fontWeight={500} as='h4' size='lg' mb={3}>
              3. Personal Data
            </Heading>
            <Text fontSize='md'>
              While using our service, we may ask you to provide us with certain personally identifiable information
              that can be used to contact or identify you. Personally identifiable information may include, but is not
              limited to.
              <br />
              <b>Data linked to you:</b>
            </Text>
            <List mt={7} spacing={3}>
              <ListItem display='flex' alignItems={'center'}>
                <Image w={5} h={5} mr={5} src={contactInfoIcon} />
                Contact Info
              </ListItem>
              <ListItem display='flex' alignItems={'center'}>
                <Image w={5} h={5} mr={5} src={privacyLocationIcon} />
                Location
              </ListItem>
              <ListItem display='flex' alignItems={'center'}>
                <Image w={5} h={5} mr={5} src={userContentIcon} />
                User Content
              </ListItem>
              <ListItem display='flex' alignItems={'center'}>
                <Image w={5} h={5} mr={5} src={searchHistoryIcon} />
                Search History
              </ListItem>
              <ListItem display='flex' alignItems={'center'}>
                <Image w={5} h={5} mr={5} src={IdentifiersIcon} />
                Identifiers
              </ListItem>
              <ListItem display='flex' alignItems={'center'}>
                <Image w={5} h={5} mr={5} src={UsageDataIcon} />
                Usage Data
              </ListItem>
            </List>
          </Box>
          <Box py={4}>
            <Heading fontWeight={500} as='h4' size='lg' mb={3}>
              4. Changes to our Privacy Policy
            </Heading>
            <Text fontSize='md'>
              We may update our Privacy Policy from time to time. We will notify you of any changes by posting the new
              Privacy Policy on this page. If a there's a major Privacy Policy change we can always send an email or you
              can always view your Privacy Policy. You are advised to review this Privacy Policy periodically for any
              changes. Changes to this Privacy Policy are effective when they are posted on this page.
            </Text>
          </Box>
          <Box py={4}>
            <Heading fontWeight={500} as='h4' size='lg' mb={3}>
              5. Contact Us
            </Heading>
            <Text fontSize='md'>
              If you have any questions about this Privacy Policy, you can contact us via email at{' '}
              <Link color='#126EFA' href='mailto: partners@goconf.io'>
                partners@goconf.io
              </Link>
            </Text>
          </Box>
        </Stack>
      </Flex>
      <Footer />
    </>
  )
}
