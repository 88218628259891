import { PlusSquareIcon } from '@chakra-ui/icons'
import {
  Box,
  Button,
  Container,
  Divider,
  Flex,
  Grid,
  GridItem,
  Heading,
  Icon,
  Image,
  Link,
  Stack,
  Text,
  useDisclosure,
  useMediaQuery,
  VStack
} from '@chakra-ui/react'
import { Star, StarBorder } from '@mui/icons-material'
import LaunchIcon from '@mui/icons-material/Launch'
import LinkedInIcon from '@mui/icons-material/LinkedIn'
import dayjs from 'dayjs'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'
import Banner from 'src/assets/images/banner-conference-details.png'
import { default as defImg, default as noCover } from 'src/assets/images/nocover.jpg'
import { Footer } from 'src/components/footer/Footer'
import { Header } from 'src/components/header/Header'
import { SvgIcon } from 'src/components/icon/SvgIcon'
import { NotFoundPage } from 'src/screens/not-found/NotFoundPage'
import { ConferencesService } from 'src/service/conferences/ConferencesService'
import { CalendarIcon, EventIcon, LocationIcon } from 'src/shared/icons/Icons'
import { useAuth } from 'src/user/useAuth'
import { ConferenceMapModal } from './CondefernceMapModal'
import { ConferenceDetailPageSkeleton } from './ConferenceDetailPageSkeleton'

export const ConferenceDetailPage = () => {
  const { conferenceSlug } = useParams()
  const conferenceService = useMemo(() => new ConferencesService(), [])
  const [loading, setLoading] = useState(true)
  const [showAll, setShowAll] = useState(false)
  const [pageDoesNotExist, setPageDoesNotExist] = useState(null)
  const [isMobile] = useMediaQuery('(max-width: 640px)')

  const { user } = useAuth()

  const [conference, setConference] = useState()

  const returnDate = (startDate, endDate) => {
    if (dayjs(startDate).diff(dayjs(endDate), 'day') === 0) {
      return dayjs(endDate).format(`MMMM D, YYYY`)
    }
    return dayjs(endDate).format(`MMMM ${dayjs(startDate).format('D')} - D, YYYY`)
  }

  const returnLocation = (address) => {
    if (address === null) {
      return '...'
    } else {
      return `${address?.unit !== null ? `${address?.unit} ` : ''}${
        address?.street !== null ? `${address?.street},` : ''
      } ${address?.city !== null ? `${address?.city},` : ''} ${address?.state !== null ? address?.state : ''} ${
        address?.country !== null ? `${address?.country}` : ''
      }`
    }
  }

  const returnEventType = (eventType) => {
    const e = eventType?.toLowerCase().replace('_', ' ').split(' ')
    let eventTypeUppercase = e.map((a) => a.charAt(0).toUpperCase() + a.substr(1))

    return eventTypeUppercase.toString().replace(',', ' ')
  }

  const initData = useCallback(async () => {
    const { data, error } = await conferenceService.getConferenceBySlug(conferenceSlug)
    if (error) {
      console.log('Error', error)
      return
    }
    if (!data) {
      setPageDoesNotExist(true)
    }
    setConference(data)
    setTimeout(() => {
      setLoading(false)
    }, 500)
  }, [conferenceSlug, conferenceService])

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0
    })

    initData()
  }, [initData])

  const handleImInterested = async () => {
    const { error } = await conferenceService.switchFavorite(user.member_id, conferenceSlug)
    if (error) {
      console.log('Error', error)
      return
    }
    initData()
  }

  const { isOpen, onOpen, onClose } = useDisclosure()
  if (pageDoesNotExist) return <NotFoundPage />

  return (
    <Box>
      <Header />
      <Box marginBottom={10}>
        {loading ? (
          <ConferenceDetailPageSkeleton />
        ) : (
          <>
            {conference && (
              <>
                <Box
                  backgroundImage={Banner}
                  backgroundSize='cover'
                  bgRepeat='no-repeat'
                  bgPosition='center'
                  width={{ sm: 'full', md: 'full' }}
                  mb={{ md: '-700px' }}
                  height={{ base: '35vh', md: '900px' }}
                />
                <Container
                  paddingTop={{ sm: '10', md: '30' }}
                  py={{ base: '0', sm: '2' }}
                  maxW={{ sm: '100%', md: '75%' }}
                  borderRadius={{ base: 'none', md: 'md' }}
                  marginTop='-70'
                  background='#fff'
                  border={{ base: '0', md: '0.5px solid gray' }}
                >
                  <Flex maxW={'100%'} px={1} py={2} flexDirection='column'>
                    <Stack>
                      <Flex>
                        <Box display={{ base: 'none', md: 'flex' }} alignItems='center' mr={{ base: '0', md: 3 }}>
                          <Image
                            src={conference?.logo?.url}
                            width='200px'
                            minW='200px'
                            minH='200px'
                            height='100%'
                            objectFit='cover'
                            rounded='md'
                          />
                        </Box>
                        <Box w={'100%'}>
                          <VStack
                            height={'fit-content'}
                            spacing={2}
                            align='stretch'
                            justifyContent='space-between'
                            h={'100%'}
                            mx={2}
                          >
                            <Heading bgGradient={'linear(to-l,#C93269,#2F4671)'} bgClip='text'>
                              {conference?.title}
                            </Heading>
                            <Text height={'fit-content'} as='p' marginTop='2' color='gray' fontSize='sm'>
                              {conference?.description}
                            </Text>
                            <Flex
                              height={'fit-content'}
                              wrap={'wrap'}
                              justify='stretch'
                              flexDirection={{ base: 'column', md: 'row' }}
                              width={'100%'}
                              gap={{ base: 2, lg: 0 }}
                            >
                              <Flex gap={2} alignItems='center' mr={8}>
                                <Icon as={LaunchIcon} sx={{ color: '#F58972' }} fontSize='md' />
                                <Text color='#0e76a8'>
                                  <Link isExternal href={`${conference?.website}`}>
                                    Visit Conference Website
                                  </Link>
                                </Text>
                              </Flex>
                              <Flex gap={2} alignItems='center' mr={8}>
                                <SvgIcon icon={LocationIcon} />
                                <Text maxW='100%'>{returnLocation(conference?.address)}</Text>
                              </Flex>
                              <Flex gap={2} minWidth='max-content' alignItems='center' mr={8}>
                                <SvgIcon icon={CalendarIcon} />
                                <Text>{returnDate(conference?.start_date, conference?.end_date)}</Text>
                              </Flex>
                              <Flex gap={2} minWidth='max-content' alignItems='center'>
                                <SvgIcon icon={EventIcon} />
                                <Text>{returnEventType(conference?.event_type)}</Text>
                              </Flex>
                            </Flex>
                            <Flex minWidth='max-content' alignItems='center' gap='2'>
                              {conference.address.lat && conference.address.lng !== null && (
                                <>
                                  <Button
                                    bgColor='#F26C4F'
                                    color='white'
                                    leftIcon={<PlusSquareIcon />}
                                    variant='solid'
                                    size='sm'
                                    width={{ base: '100%', md: 'auto' }}
                                    onClick={onOpen}
                                  >
                                    Show Map
                                  </Button>
                                  <ConferenceMapModal
                                    conferenceAddress={conference.address}
                                    isOpen={isOpen}
                                    onClose={onClose}
                                  />
                                </>
                              )}
                              {user && (
                                <Button
                                  onClick={handleImInterested}
                                  leftIcon={
                                    <Icon as={conference?.is_favorite ? Star : StarBorder} color='orange.400' />
                                  }
                                  colorScheme='#030B30'
                                  variant='outline'
                                  size='sm'
                                  width={{ base: '100%', md: 'auto' }}
                                >
                                  {conference?.is_favorite ? 'Interested' : "I'm interested"}
                                </Button>
                              )}
                            </Flex>
                          </VStack>
                        </Box>
                      </Flex>
                      <Divider variant='dashed' mt={2} />
                    </Stack>
                    {conference?.speakers?.length > 0 && (
                      <GridItem>
                        <Heading
                          size='md'
                          color='blackAlpha.900'
                          mt={5}
                          sx={{ paddingBottom: isMobile ? 5 : undefined }}
                        >
                          Speakers
                        </Heading>
                        <Grid
                          templateColumns={{
                            base: 'repeat(auto-fill, minmax(250px, 1fr))'
                          }}
                          py={2}
                          gap={3}
                        >
                          {conference?.speakers.slice(0, !showAll ? 5 : conference.speakers.length).map((speaker) => (
                            <GridItem key={speaker.id} w={{ base: 'auto', md: 'fit-content' }}>
                              <VStack align={isMobile ? 'center' : 'start'}>
                                <Image
                                  objectFit='cover'
                                  borderRadius={5}
                                  minH={250}
                                  minW={250}
                                  h={250}
                                  w={250}
                                  src={speaker?.image?.url ? speaker?.image?.url : defImg}
                                />
                                <Box width='full' flex={1} alignItems='start'>
                                  <Heading size='md'>{`${speaker?.first_name} ${speaker?.last_name}`}</Heading>
                                  <Text
                                    fontSize='small'
                                    marginBottom={{ md: '5' }}
                                  >{`${speaker?.position} at ${speaker?.company}`}</Text>
                                  <Link href={speaker?.linkedin_url} isExternal>
                                    <Icon color='#0e76a8' as={LinkedInIcon} />
                                  </Link>
                                </Box>
                              </VStack>
                            </GridItem>
                          ))}
                        </Grid>

                        {conference?.speakers.length > 5 && (
                          <Box textAlign='center'>
                            <Button
                              _focus={{ outline: 'none !important' }}
                              colorScheme='orange'
                              variant='link'
                              fontSize='larger'
                              onClick={() => setShowAll(!showAll)}
                            >
                              Show {!showAll ? 'All' : 'Less'}
                            </Button>
                          </Box>
                        )}
                        <Divider variant='dashed' mt={2} />
                      </GridItem>
                    )}

                    {conference?.organization && (
                      <Flex flexDirection={'column'} my={5}>
                        <Heading size='md' color='blackAlpha.900' mb={3}>
                          Organizer information
                        </Heading>
                        <Flex flexDirection={{ base: 'column', md: 'row' }}>
                          <Image
                            h={'175px'}
                            w={'175px'}
                            src={conference?.organization?.image?.url ?? noCover}
                            borderRadius='5px'
                          />
                          <Box mx={{ base: '0', md: 5 }} mt={3}>
                            <VStack spacing={2} align='flex-start'>
                              <Heading as='h4' size='md'>
                                {conference?.organization?.name}
                              </Heading>
                              <Text as='p' marginTop='2' color='gray' fontSize='sm'>
                                {conference?.organization?.description}
                              </Text>
                            </VStack>
                            <Flex gap={2} alignItems='center'>
                              <Icon as={LaunchIcon} sx={{ color: '#F58972' }} fontSize='md' />
                              <Text color='#0e76a8'>
                                <Link isExternal href={`${conference?.organization?.website}`}>
                                  Visit Organization Website
                                </Link>
                              </Text>
                            </Flex>
                          </Box>
                        </Flex>
                      </Flex>
                    )}

                    <Box display='none'>
                      <Heading size='md' color='blackAlpha.900'>
                        Ticket information
                      </Heading>
                      <VStack spacing={8} mt={6}>
                        <Flex
                          justifyContent={'start'}
                          w={'100%'}
                          alignItems={{ base: 'start', md: 'center' }}
                          direction={{ base: 'column', md: 'row' }}
                        >
                          <Heading size='sm' minW={{ base: '100%', md: '120', lg: '250px' }} mb={{ base: 4, md: 0 }}>
                            Average Price
                          </Heading>
                          <Heading size='md' color='#BF2F66'>
                            $170 USD
                          </Heading>
                        </Flex>

                        <Flex
                          justifyContent={'start'}
                          w={'100%'}
                          alignItems={{ base: 'start', md: 'center' }}
                          direction={{ base: 'column', md: 'row' }}
                        >
                          <Heading size='sm' minW={{ base: '100%', md: '120', lg: '250px' }} mb={{ base: 4, md: 0 }}>
                            Sources
                          </Heading>
                          <Flex flexWrap={'wrap'}>
                            <Link color='#00B2F4' href='#' mr={8}>
                              <Text>www.eventbrite.com/google-ai-and-help</Text>
                            </Link>
                            <Link color='#00B2F4' href='#'>
                              <Text>www.eventbrite.com/google-ai-and-help</Text>
                            </Link>
                          </Flex>
                        </Flex>
                        <Flex
                          justifyContent={'start'}
                          w={'100%'}
                          alignItems={{ base: 'start', md: 'center' }}
                          direction={{ base: 'column', md: 'row' }}
                        >
                          <Heading
                            size='sm'
                            color='gray.300'
                            minW={{ base: '100%', md: '120', lg: '250px' }}
                            mb={{ base: 4, md: 0 }}
                          >
                            Disclaimer
                          </Heading>
                          <Box>
                            <Text color='gray.400'>
                              GoConf only provides information about the tickets and is in no way responsible for
                              refunds or cancelations of the event.
                            </Text>
                          </Box>
                        </Flex>
                      </VStack>
                    </Box>
                  </Flex>
                </Container>
              </>
            )}
          </>
        )}
      </Box>
      <Footer />
    </Box>
  )
}
