import { SearchIcon } from '@chakra-ui/icons'
import { Flex, Input, InputGroup, InputRightAddon, Stack, useMediaQuery, VStack} from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useState } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { BaseButton } from 'src/components/button/BaseButton'
import { ConferencesService } from 'src/service/conferences/ConferencesService'
import { resolveParamValue, TIMELINE } from 'src/shared/constants'
import { useQuery } from 'src/shared/hooks/useQuery'
import { Footer } from '../../components/footer/Footer'
import { Header } from '../../components/header/Header'
import { Filters } from './Filters'
import { FiltersMobile } from './FiltersMobile'
import ResultsList from './ResultsList'

export const SearchPage = () => {
  const [searchInput, setSearchInput] = useState('')

  const [isMobile] = useMediaQuery('(max-width: 640px)')
  const [isMedium] = useMediaQuery('(max-width: 820px)')
  const query = useQuery()
  const navigate = useNavigate()
  const location = useLocation()
  const [conferences, setConferences] = useState([])
  const conferencesService = useMemo(() => new ConferencesService(), [])
  const [loading, setLoading] = useState(true)
  const [eventType, setEventType] = useState()
  const [eventSize, setEventSize] = useState()
  const [timeline, setTimeline] = useState()

  const initData = useCallback(async () => {
    const isExplore = location.pathname.includes('/explore')
    const searchQuery = query.get('q')

    setSearchInput(searchQuery ?? '')
    if (searchQuery || eventSize || eventType || timeline || isExplore) {
      const query = isExplore ? '' : searchQuery

      const { data, error } = await conferencesService.searchConferences({
        q: query,
        type: resolveParamValue('type', eventType),
        size: resolveParamValue('size', eventSize),
        t: timeline ?? TIMELINE.UPCOMING
      })
      if (error) {
        console.log('Error')
        return
      }
      setTimeout(() => {
        setLoading(false)
        setConferences(data)
      }, 500)
    }
  }, [conferencesService, location, query, eventType, eventSize, timeline])

  useEffect(() => {
    if (query.get('type')) {
      setEventType(query.get('type'))
    }
    if (query.get('size')) {
      setEventSize(query.get('size'))
    }
    if (query.get('timeline')) {
      setTimeline(query.get('timeline'))
    } else {
      setTimeline(TIMELINE.UPCOMING)
    }
    window.scrollTo({
      top: 0,
      left: 0
    })
    initData()
  }, [query, initData])

  const navigateTo = (key, value) => {
    let params = location.search

    const symbol = params?.charAt(0) === '?' ? '&' : '?'
    if (params?.includes(key)) {
      const arr = params.replace('?', '').split('&')
      const prevValue = arr?.find((i) => i?.split('=')?.[0] === key)?.split('=')?.[1]
      params = params.replace(prevValue, value)
    } else {
      params += `${symbol}${key}=${value}`
    }

    const route = `/search${params}`

    navigate(route)
  }

  const onEventTypeHandle = (value) => {
    setEventType(value)
    navigateTo('type', value)
  }

  const onEventSizeHandle = (value) => {
    setEventSize(value)
    navigateTo('size', value)
  }

  const onTimelineHandle = (value) => {
    setTimeline(value)
    navigateTo('timeline', value)
  }

  const onResetFilters = () => {
    setEventType('')
    setEventSize('')
    setSearchInput('')
    setTimeline(TIMELINE.UPCOMING)
    navigate('/explore')
  }

  const handleOnSearch = () => {
    if (!!searchInput.length) {
      let route = `/search?q=${searchInput}`

      if (query.get('type')) {
        route += `&type=${query.get('type')}`
      }
      if (query.get('size')) {
        route += `&size=${query.get('size')}`
      }
      if (query.get('timeline')) {
        route += `&timeline=${query.get('timeline')}`
      }
      navigate(route)
    }
  }

  return (
    <Flex direction={'column'} justifyContent='space-between' minH={'100vh'}>
      <Stack w={'full'}>
        <Header />
      </Stack>
      <VStack display={'flex'} spacing={isMobile ? '15px' : '40px'} mt={'30px'} mb={'50px'}>
        <Stack alignItems={'center'} w={'full'}>
          <InputGroup size='lg' overflow='hidden' w={{ base: '350px', md: '650px', lg: '900px', xl: '1100' }}>
            <Input
              placeholder='Conference name or location'
              _placeholder={{
                fontSize: { base: '13px', md: 'medium', lg: 'large' },
                color: 'rgba(102, 102, 102, 1)'
              }}
              variant={'unstyled'}
              pl={4}
              backgroundColor={'rgba(255, 255, 255, 1)'}
              border='1px'
              borderRadius='7px'
              borderRight='none'
              borderColor={'rgba(226, 232, 240, 1)'}
              onChange={(e) => setSearchInput(e.target.value)}
              value={searchInput}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  handleOnSearch()
                }
              }}
            />
            <InputRightAddon backgroundColor='white'>
              <Flex mr={isMedium ? '-4' : '-2'}>
                <BaseButton
                  type='submit'
                  backgroundColor={isMedium ? 'white' : '#F26C4F'}
                  color={isMedium ? '#F26C4F' : 'white'}
                  _focus={{
                    boxShadow: 'none'
                  }}
                  label='Search'
                  w='full'
                  variant='text'
                  cursor='pointer'
                  icon={<SearchIcon />}
                  onClick={handleOnSearch}
                />
              </Flex>
            </InputRightAddon>
          </InputGroup>
        </Stack>
        <Stack
          direction={{ base: 'column', lg: 'row' }}
          alignItems={'start'}
          // spacing={'16px'}
          w={{ base: '350px', sm: '350px', md: '650px', lg: '900px', xl: '1170px' }}
        >
          {!isMedium ? (
            <Filters
              eventSize={eventSize}
              eventType={eventType}
              onEventSizeHandle={onEventSizeHandle}
              onEventTypeHandle={onEventTypeHandle}
              onResetFilters={onResetFilters}
            />
          ) : (
            <FiltersMobile
              eventSize={eventSize}
              eventType={eventType}
              onEventSizeHandle={onEventSizeHandle}
              onEventTypeHandle={onEventTypeHandle}
              onResetFilters={onResetFilters}
            />
          )}
          <VStack spacing={'20px'}>
            <ResultsList
              conferences={conferences}
              loading={loading}
              timeline={timeline}
              onTimelineHandle={onTimelineHandle}
            />
          </VStack>
        </Stack>
      </VStack>
      <Stack w={'full'}>
        <Footer />
      </Stack>
    </Flex>
  )
}
