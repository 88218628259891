import { EmailIcon, PhoneIcon } from '@chakra-ui/icons'
import { Box, Heading, HStack, Image, Link, Stack, Text, VStack } from '@chakra-ui/react'
import facebookLogo from 'src/assets/facebookLogo.svg'
import instagramLogo from 'src/assets/instagramLogo.svg'
import linkedInLogo from 'src/assets/linkedInLogo.svg'
import locationIcon from 'src/assets/location.svg'
const CareerPageInfo = () => {
  return (
    <Stack w={{ base: '100%', md: '50%' }} m='auto' bg={'#030B30'} my={3} px={5} py={8} borderRadius={'10px'}>
      <Box>
        <Heading as='h5' size='md' color={'#fff'}>
          We do not have any open positions at the moment
        </Heading>
        <Text color={'#fff'} my={3} fontSize='md'>
          Send us your CV & we'll get back to you
        </Text>
      </Box>
      <VStack w='fit-content' py={{ md: 8 }}>
        <HStack w='100%' spacing={10} py={3} color={'#fff'}>
          <EmailIcon w={6} h={6} color='#F26C4F' />
          <Text>careers@goconf.io</Text>
        </HStack>
        <HStack w='100%' spacing={10} py={3} color={'#fff'}>
          <PhoneIcon w={6} h={6} color='#F26C4F' />
          <Text>123 456 8909</Text>
        </HStack>
        <HStack w='100%' spacing={10} py={3} color={'#fff'}>
          <Image fill='#F26C4F' src={locationIcon} />
          <Text>North Macedonia</Text>
        </HStack>
      </VStack>
      <HStack py={5} w='100%' justify='space-evenly'>
        <Link href='https://facebook.com/' isExternal>
          <Image w={{ base: 8, md: 10 }} h={{ base: 8, md: 10 }} src={facebookLogo} alt='Facebook Logo' />
        </Link>
        <Link href='https://instagram.com/' isExternal>
          <Image w={{ base: 8, md: 10 }} h={{ base: 8, md: 10 }} src={instagramLogo} alt='Instagram Logo' />
        </Link>
        <Link href='https://linkedin.com' isExternal>
          <Image w={{ base: 8, md: 10 }} h={{ base: 8, md: 10 }} src={linkedInLogo} alt='Linkedin Logo' />
        </Link>
      </HStack>
    </Stack>
  )
}

export default CareerPageInfo
