import { useLocation } from 'react-router-dom'
import { LoginCard } from 'src/components/login/LoginCard'
import { LoginLayout } from 'src/components/login/LoginLayout'

export const LoginPage = () => {
  const location = useLocation()
  return (
    <LoginLayout prop={location.pathname}>
      <LoginCard />
    </LoginLayout>
  )
}
