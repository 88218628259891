import { EditIcon } from '@chakra-ui/icons'
import {
  Avatar,
  Button,
  Flex,
  FormControl,
  FormLabel,
  HStack,
  Image,
  Input,
  Spinner,
  Stack,
  VStack
} from '@chakra-ui/react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import DefaultBannerPicture from 'src/assets/images/profile-default-banner.png'
import { MemberService } from 'src/service/members/MemberService'
import LoadingModal from '../modals/LoadingModal'
import PasswordModal from '../modals/PasswordModal'

export const PersonalInformationPanel = ({
  name,
  lastName,
  email,
  headerPicture,
  memberId,
  profile,
  refetchData,
  initialFetch
}) => {
  const [editName, setEditName] = useState(false)
  const [initialFirstName, setFirstName] = useState(name)
  const [initialLastName, setLastName] = useState(lastName)
  const [initialEmail, setInitialEmail] = useState(email)
  const [editEmail, setEditEmail] = useState(false)
  const [profilePicture, setProfilePicture] = useState(null)
  const [coverPicture, setCoverPicture] = useState(null)
  const [passwordModal, setPasswordModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const memberService = useMemo(() => new MemberService(), [])
  const profilePic = useRef(null)
  const headerPic = useRef(null)

  useEffect(() => {
    setFirstName(name)
    setLastName(lastName)
    setInitialEmail(email)
  }, [name, lastName, email])

  const onUpdateUserHandler = useCallback(async () => {
    setLoading(true)
    const formData = new FormData()
    formData.append('first_name', initialFirstName ?? null)
    formData.append('last_name', initialLastName ?? null)
    if (profilePicture) {
      formData.append('profile', profilePicture ?? null)
    }
    if (coverPicture) {
      formData.append('cover', coverPicture ?? null)
    }

    if (memberId) {
      const { error } = await memberService.updateMember(memberId, formData)
      if (error) {
        setLoading(false)
        console.log('Error', error)
        return
      }
      setEditName(false)
      setProfilePicture(null)
      refetchData()
    }
    setLoading(false)
  }, [coverPicture, initialFirstName, initialLastName, memberId, memberService, profilePicture, refetchData])

  const onCancelNameHandler = () => {
    setFirstName(name)
    setLastName(lastName)
    setEditName(false)
  }

  const onCancelEmailEditHandler = () => {
    setInitialEmail(email)
    setEditEmail(false)
  }

  return (
    <>
      <LoadingModal isOpen={loading} />
      <FormControl justifyContent='space-between' display='flex'>
        <Stack width='100%' spacing={4}>
          <HStack w={'100%'} justifyContent='space-between'>
            <FormLabel>First Name</FormLabel>
            <Flex w={'60%'} justifyContent='space-between' alignItems='center'>
              <Input
                w={'90%'}
                disabled={!editName}
                id={'first_name'}
                name={'first_name'}
                value={initialFirstName}
                borderColor={'#C2C2C2'}
                onChange={(e) => setFirstName(e.target.value)}
              />
              <EditIcon
                w={5}
                h={5}
                color={'#999'}
                style={{ cursor: 'pointer' }}
                onClick={() => setEditName(!editName)}
              />
            </Flex>
          </HStack>
          <HStack w={'100%'} justifyContent='space-between'>
            <FormLabel>Last Name</FormLabel>
            <Flex w={'60%'} justifyContent='space-between' alignItems='center'>
              <Input
                w={'90%'}
                disabled={!editName}
                id={'last_name'}
                name={'last_name'}
                value={initialLastName}
                borderColor={'#C2C2C2'}
                onChange={(e) => setLastName(e.target.value)}
              />
            </Flex>
          </HStack>
          {editName && (
            <HStack justifyContent='flex-end'>
              <Button
                size={'sm'}
                variant='outlined'
                color='black'
                border={'1px solid #111'}
                onClick={onCancelNameHandler}
              >
                Cancel
              </Button>
              <Button onClick={onUpdateUserHandler} color='white' backgroundColor={'black'} size={'sm'}>
                Save
              </Button>
            </HStack>
          )}
          <HStack w={'100%'} justifyContent='space-between'>
            <FormLabel>Email</FormLabel>
            <Flex w={'60%'} justifyContent='space-between' alignItems='center'>
              <Input
                w={'90%'}
                disabled={!editEmail}
                id={'email'}
                name={'email'}
                value={initialEmail}
                borderColor={'#C2C2C2'}
                onChange={(e) => setInitialEmail(e.target.value)}
              />
              <EditIcon
                w={5}
                h={5}
                color={'#999'}
                style={{ cursor: 'pointer' }}
                onClick={() => setEditEmail(!editEmail)}
              />
            </Flex>
          </HStack>
          {editEmail && (
            <HStack justifyContent='flex-end'>
              <Button
                size={'sm'}
                variant='outlined'
                color='black'
                border={'1px solid #111'}
                onClick={onCancelEmailEditHandler}
              >
                Cancel
              </Button>
              <Button color='white' backgroundColor={'black'} size={'sm'}>
                Save
              </Button>
            </HStack>
          )}
          <HStack w={'100%'} justifyContent='space-between'>
            <FormLabel>Password</FormLabel>
            <Button onClick={() => setPasswordModal(true)} size={'sm'}>
              Change Password
            </Button>
          </HStack>
          <HStack alignItems={'self-start'} justifyContent='space-between'>
            <FormLabel>Profile Picture</FormLabel>
            <VStack>
              {!initialFetch ? (
                profile ? (
                  <Image
                    boxSize='100px'
                    src={profile && profile.url}
                    alt='Profile Picture'
                    borderRadius='10px'
                    alignSelf={'end'}
                    filter={'grayScale(75%)'}
                    opacity={0.75}
                  />
                ) : (
                  <Avatar alignSelf={'end'} size={'xl'} name={`${name} ${lastName}`} />
                )
              ) : (
                <Spinner alignSelf={'end'} boxSize='75px' />
              )}

              <HStack>
                <Button variant={'link'} size={'sm'}>
                  Remove Picture
                </Button>
                <Button size={'sm'} onClick={() => profilePic.current.click()}>
                  Change Profile Picture
                </Button>
                <Input
                  ref={profilePic}
                  type='file'
                  accept='image/*'
                  display='none'
                  onChange={(e) => setProfilePicture(e.target.files[0] ?? null)}
                />
              </HStack>
            </VStack>
          </HStack>
          <HStack alignItems={'self-start'} justifyContent='space-between'>
            <FormLabel>Header Picture</FormLabel>
            <VStack>
              {!initialFetch ? (
                <Image
                  h={75}
                  w={150}
                  maxW={150}
                  src={headerPicture ? headerPicture.url : DefaultBannerPicture}
                  alt='Header Picture'
                  alignSelf={'end'}
                  borderRadius='10px'
                  filter={'grayScale(75%)'}
                  opacity={0.75}
                />
              ) : (
                <Spinner alignSelf={'end'} boxSize='75' />
              )}
              <HStack>
                <Button variant={'link'} size={'sm'}>
                  Remove Picture
                </Button>
                <Button size={'sm'} onClick={() => headerPic.current.click()}>
                  Change Header Picture
                </Button>
                <Input
                  ref={headerPic}
                  type='file'
                  accept='image/*'
                  display='none'
                  onChange={(e) => setCoverPicture(e.target.files[0] ?? null)}
                />
              </HStack>
            </VStack>
          </HStack>
        </Stack>
      </FormControl>

      <PasswordModal
        onOpen={passwordModal}
        onCloseModal={() => {
          setPasswordModal(false)
        }}
      />
    </>
  )
}
