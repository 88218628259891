import { Box, Flex, Heading, Image, Link, SimpleGrid, Text } from '@chakra-ui/react'
import GoconfCover from 'src/assets/images/goconf-cover.png'
import GoconfUplift from 'src/assets/images/goconf-uplift.png'
import { Footer } from 'src/components/footer/Footer'
import { Header } from 'src/components/header/Header'

export const AboutUsPage = () => {
  return (
    <Box>
      <Header />
      <Flex p={{ base: 10, md: 20 }} justifyContent={{ base: 'center', md: 'start' }}>
        <Heading color='#F26C4F'>About us</Heading>
      </Flex>
      <SimpleGrid columns={{ sm: 1, md: 2 }} px={{ base: 10, md: 20 }} spacing={{ base: 10, md: 20 }}>
        <Box>
          <Heading sx={{ marginBottom: 4 }} size='md'>
            History
          </Heading>
          <Text sx={{ marginBottom: { base: 5, md: 12 } }}>
            The idea for GoConf was born in late 2019 and as most business were, we also were impacted by the pandemic.
            That said, we had to take a break and see what the future holds. In late 2021 when the world started opening
            back up more rapidly, we knew it was time to get back on our feet. We attended two accelerator programs that
            helped us define our market, core values and acted as a compass for our product overall. From the beginning
            of 2022 we’ve been working on the product passionately and had our official soft launch in September of
            2022. Since then we’ve never looked back and are thrilled to see what the future brings.
          </Text>
          <Text>
            We have been financially supported by the Fund for Innovation & Technology development in the Republic of
            North Macedonia with a grant of $40,000 and have also received grants from prestigious brands that help
            accelerate startups such as the Amazon Web Services Partner Network and SendGrid (Twilio).
          </Text>
        </Box>
        <Box display='flex' alignContent='flex-end' alignItems='flex-end' justifyContent='flex-end'>
          <Image src={GoconfUplift} />
        </Box>
        <Box sx={{ display: { base: 'none', md: 'block' } }}>
          <Image src={GoconfCover} />
        </Box>
        <Box>
          <Heading sx={{ marginBottom: 4 }} size='md'>
            Mission & Vision
          </Heading>
          <Text sx={{ marginBottom: { base: 5, md: 12 } }}>
            Our mission is to be the leading global platform that people to refer to for anything tech conference
            related. What started of as a conference marketplace is slowly evolving into much more and we want to
            provide value for our users as well as our conference partners.
          </Text>
          <Text sx={{ marginBottom: { base: 5, md: 12 } }}>
            As for our vision, we want to expand into a platform that event organizers and hosts can use to find
            speakers for their event and while this is still a work in progress, we believe we have what it takes to
            make this happen.
          </Text>
          <Text>
            As a team, it is very important to us to offer value to both ends, conference visitors as well as
            organizers.
          </Text>
        </Box>
        <Box sx={{ display: { base: 'block', md: 'none' } }}>
          <Image src={GoconfCover} />
        </Box>
      </SimpleGrid>
      <Flex p={{ base: 10, md: 20 }}>
        <SimpleGrid spacing={5}>
          <Heading size='md'>The team</Heading>
          <Text>
            We are a small team of 10 people, but with big dreams and dedication. With over 20+ years of experience in
            product development and shipment, we are confident that we can take the GoConf to where we want it to be. Do
            we need help? Of course we do and we would greatly appreciate it. Be it marketing, financial services,
            design or development, we are always open to new ideas. If you want to join our team or have any sort of
            feedback, feel free to send an email to{' '}
            <Link color='blue.500' href='mailto: partners@goconf.io'>
              partners@goconf.io
            </Link>{' '}
            and someone will get back to you within a couple of days.
          </Text>
        </SimpleGrid>
      </Flex>
      <Footer />
    </Box>
  )
}
