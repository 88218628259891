import { Flex, Image, Stack, Text, useMediaQuery } from '@chakra-ui/react'
import World from '../../assets/images/world.png'
import Logo from '../../assets/logo.svg'

export const LoginLayout = ({ children, prop }) => {
  const [isMobile] = useMediaQuery('(max-width: 640px)')

  return (
    <Flex width={'full'} height={'100vh'} bgGradient='linear(102.55deg, #030B30 0.15%, #172047 99.97%)'>
      <Stack
        display={'flex'}
        direction={{ base: 'column-reverse', sm: 'row' }}
        justifyContent={'center'}
        width={'full'}
      >
        <Flex
          display={'center'}
          width={'full'}
          justifyContent={'center'}
          alignItems={'center'}
          bgColor={'#F9F9F9'}
          height='100%'
        >
          {children}
        </Flex>
        <Flex
          display={isMobile ? 'none' : 'flex'}
          width={'full'}
          backgroundImage={World}
          height={'100%'}
          backgroundRepeat='no-repeat'
          justifyContent={'center'}
          bgPosition={'right'}
        >
          <Flex height={'auto'} direction={'column'} justifyContent={'center'} alignItems={'center'}>
            <Image src={Logo} height={'49px'} />
            <Text align={'center'} color={'rgba(255, 255, 255, 1)'} fontWeight='700' fontSize={'32px'}>
              {prop === '/login/forgotpw'
                ? 'Helping you navigate, the big world of conferences.'
                : 'Connecting conferences, internationally.'}
            </Text>
          </Flex>
        </Flex>
      </Stack>
    </Flex>
  )
}
