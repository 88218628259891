import { Box, Skeleton, SkeletonText, Stack, useMediaQuery } from '@chakra-ui/react'

export const SearchCardSkelton = () => {
  const [isMobile] = useMediaQuery('(max-width: 640px)')

  return (
    <Box
      display={'flex'}
      maxW={isMobile ? '340px' : '902px'}
      backgroundColor={'rgba(255, 255, 255, 1)'}
      border={'1px'}
      borderColor={'rgba(230, 230, 230, 1)'}
      boxShadow={'-4px 4px 8px 0px rgba(0, 0, 0, 0.07)'}
      borderRadius={'5px'}
    >
      <Box w={'full'} margin={'15px'}>
        <Stack direction={'row'} spacing={'15px'}>
          <Skeleton w={isMobile ? '90px' : '300px'} h={isMobile ? '90px' : '180px'} borderRadius={'5px'} />
          <Stack display={'flex'} justifyContent={'space-evenly'}>
            <Skeleton h={isMobile ? '15px' : '25px'} />
            <Stack direction={{ base: 'column', md: 'row' }}>
              <SkeletonText
                noOfLines={3}
                spacing={isMobile ? 2 : 5}
                mt={isMobile ? 2 : 5}
                w={isMobile ? '200px' : '550px'}
              />
            </Stack>
            {!isMobile && (
              <Stack direction={'row'} spacing={'15px'}>
                <Skeleton w='120px' h='35px' />
                <Skeleton w='120px' h='35px' />
              </Stack>
            )}
          </Stack>
        </Stack>
      </Box>
    </Box>
  )
}
