import { Box, Heading, Image, Link, Stack, useMediaQuery } from '@chakra-ui/react'

const FooterItem = ({ items, title }) => {
  const [isMobile] = useMediaQuery('(max-width: 640px)')

  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Stack direction={isMobile ? 'column' : 'row'}>
        <Heading
          as='h2'
          size='sm'
          sx={{ fontSize: '16px', color: '#F26C4F', minWidth: 70, fontWeight: 600, marginRight: '40px' }}
        >
          {title}
        </Heading>
        <Stack direction={isMobile && title !== 'Connect' && 'column'} sx={{ alignItems: !isMobile && 'center' }}>
          {items.map((item, index) => (
            <Stack key={index}>
              <Link href={item.link} isExternal={item.isExternal}>
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  {item?.icon && (
                    <Box sx={{ marginRight: '9.5px' }}>
                      <Image src={item.icon} alt={item.icon} />
                    </Box>
                  )}
                  {item?.icon && !isMobile && (
                    <Heading as='h2' sx={{ fontSize: '12px', color: '#FFFFFF', fontWeight: 400, marginRight: '25px' }}>
                      {item.title}
                    </Heading>
                  )}
                  {!item?.icon && (
                    <Heading as='h2' sx={{ fontSize: '12px', color: '#FFFFFF', fontWeight: 400, marginRight: '25px' }}>
                      {item.title}
                    </Heading>
                  )}
                </Box>
              </Link>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </Box>
  )
}

export default FooterItem
