import { Box, Heading, Image, Text, VStack } from '@chakra-ui/react'
import ComingSoonCover from 'src/assets/images/coming-soon-cover.png'
import { Footer } from 'src/components/footer/Footer'
import { Header } from 'src/components/header/Header'
export const PublishEventPage = () => {
  return (
    <Box>
      <Header />
      <Box width={{ sm: 'full', md: 'full' }} h='full'>
        <VStack textAlign='center'>
          <Box mb={5} mt={10}>
            <Heading bgGradient={'linear(to-r,#0086D8,#004D7C)'} bgClip='text' fontWeight='600'>
              Coming Soon...
            </Heading>
          </Box>
          <Box padding={10}>
            <Image src={ComingSoonCover} />
          </Box>
          <Box paddingX={{ base: 4 }}>
            <Heading color='#030B30' as='h5' size='md'>
              Join thousands of other events published on GoConf.
            </Heading>
            <Text mt={{ base: 4, md: 4 }} mb={{ base: 4, md: 5 }}>
              GoConf is the perfect platform for conference hosts like yourself to publish their event and have it
              advertised to the right groups of people.
            </Text>
          </Box>
        </VStack>
      </Box>
      <Footer />
    </Box>
  )
}
