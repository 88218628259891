import React, { useEffect, useRef, useState } from 'react'

// This component detects if a certain element is on focus (is inside the viewport)
export const ScrollDetector = ({ Component }) => {
  const targetRef = useRef(null)
  const [isVisible, setIsVisible] = useState(false)

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(entry.isIntersecting)
        }
      },
      {
        root: null,
        rootMargin: '0px',
        threshold: 0.5
      }
    )

    const currentRef = targetRef.current

    if (currentRef) {
      observer.observe(currentRef)
    }

    return () => {
      if (currentRef) {
        observer.unobserve(currentRef)
      }
    }
  }, [])

  return <div ref={targetRef}>{isVisible ? Component : null}</div>
}
